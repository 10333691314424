export const getStatusTextColor = (orderStatus: string): string => {
  switch (orderStatus) {
    case 'CANCELED':
      return 'rgb(255,0,0)';
    case 'PENDING':
      return 'rgb(14, 112, 144)';
    case 'PAID':
      return 'rgba(24, 232, 6, 1)';
    case 'PROCESSING':
      return 'rgb(248,213,104)';
    case 'EXPIRED':
      return 'rgba(235, 87, 87, 1)';
    case 'ACCEPTED':
      return '#18E806';
    case 'success':
      return '#18E806';
    default:
      return 'rgb(181, 71, 8)';
  }
};

export const getPayoutStatusTextColor = (orderStatus: string): string => {
  switch (orderStatus) {
    case 'CANCELED':
      return 'rgba(235, 87, 87, 1)';
    case 'PENDING':
      return 'rgb(14, 112, 144)';
    case 'CONFIRMED':
      return 'rgba(24, 232, 6, 1)';
    case 'PROCESSING':
      return 'rgb(181, 71, 8)';
    case 'CANCELED BY OPERATOR':
      return 'rgb(180, 35, 24)';
    default:
      return 'rgb(181, 71, 8)';
  }
};
