import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Sheet,
  Modal,
  Switch,
  Table,
  Input,
  Typography,
  Select,
  Option,
  FormControl,
  FormLabel,
  selectClasses,
  IconButton,
  switchClasses,
  Theme,
} from '@mui/joy';

import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useWidth from '../../hooks/useWidth';
import { getGlobalUser } from '../../redux/auth/selectors';
import { phonePattern } from '../../utils/patterns';
import Error from '../Error';
import { Terminal } from '../../utils/types';
import CardItemQAC from './CardItemQAC';
import css from './CardList.module.css';
import { useJar } from '../../hooks/useJar';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import ArchivedTerminalItem from './ArchivedTerminalItem';
import IconComponent from '../IconComponent/IconComponent';
import { getConstants } from '../../services/operators';

interface IFormInput {
  jar_link: string;
  bank: string;
  limit_by_month: number;
  limit_by_the_day: number;
  label: string;
  accessibility: boolean;
  daily_transaction_limit: number;
}

const CardListQAC = () => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchBank, setSearchBank] = useState<string>('');
  const { isMobile } = useWidth();
  const { user } = useSelector(getGlobalUser);
  const [pageSizeConst, setPageSizeConst] = useState<number | null>(null);
  const [limitByMonth, setLimitByMonth] = useState('');
  const [limitByDay, setLimitByDay] = useState('');
  const [dailyTransactionLimit, setDailyTransactionLimit] = useState('');
  const {
    count,
    cards,
    banks,
    handleAddCart,
    handleDeleteCard,
    handleUpdateCard,
    handleRestSearch,
    handlePageClick,
    handleEditCard,
    archItems,
    countArch,
    handleArchPageClick
  } = useJar({
    userId: user.id,
    searchBank,
    setSearchBank,
    pageSize: pageSizeConst,
  });

  const pageCount = Math.ceil(count / (pageSizeConst || 10));
  const pageCountArch = Math.ceil(countArch / (pageSizeConst || 10));

  const [isFocused, setIsFocused] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    reset,
  } = useForm<IFormInput>({
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<IFormInput> = ({
    jar_link,
    accessibility,
    bank,
    limit_by_month,
    limit_by_the_day,
    label,
    daily_transaction_limit,
  }: {
    jar_link: string;
    accessibility: boolean;
    bank: string | number;
    limit_by_month: number;
    limit_by_the_day: number;
    label: string;
    daily_transaction_limit: number;
  }) => {
    handleAddCart({
      jar_link,
      bank: parseFloat(bank as string),
      accessibility: (accessibility && accessibility) || false,
      label,
      limit_by_the_day,
      limit_by_month,
      daily_transaction_limit,
    });
    setModalOpen(!modalOpen);
    reset();
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const constants = await getConstants();
        if (constants) {
          setPageSizeConst(Number(constants.DEFAULT_PAGINATION));
          setLimitByMonth(constants.MONTH_LIMITS_FOR_TERMINALS);
          setLimitByDay(constants.DAY_LIMITS_FOR_TERMINALS);
          setDailyTransactionLimit(constants.ACQUIRING_TRANSACTION_DAILY_LIMIT);
        }  
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);
  const handleChangeBank = (_event: React.SyntheticEvent | null, newValue: string | null) => {
    const value = newValue === 'all' ? '' : (newValue as string);
    setSearchBank(value);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flex:1 }}>
        <Box sx={{ display: 'flex', }}>
        <Select
          placeholder={t('card_search_bank')}
          variant="outlined"
          slotProps={{
            listbox: {
              sx: {
                '--List-padding': '0px',
                '--ListItem-radius': '0px',
              },
            },
          }}
          indicator={
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
              <path
                fill="#ADB5BD"
                d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
              />
            </svg>
          }
          onChange={(_event: React.SyntheticEvent | null, newValue: string | null) => {
            handleChangeBank(_event, newValue);
          }}
          value={searchBank}
          sx={{
            [`& .${selectClasses.indicator}`]: {
              transition: '0.2s',
              [`&.${selectClasses.expanded}`]: {
                transform: 'rotate(-180deg)',
              },
            },
            color: '#CED4DA',
            width: '170px',
            '--Input-radius': '8px',
            '--Select-placeholderOpacity': 1,
          }}
        >
          <Option key={'all'} value={'all'} sx={{
            p: 0,
          }}>

            <Typography sx={{
              width: '100%', height: '100%', p: 1,
              '&:hover, &:focus': {
                backgroundColor: '#684FDD',
                color: '#F1F1F1',
              },
            }}>
              {t('filter_All')}
            </Typography>
          </Option>
          {banks &&
            banks.map(({ title, id }) => (
              <Option key={id} value={id} sx={{
                p: 0,
                '&[aria-selected="true"]': {
                  backgroundColor: '#684FDD',
                  color: '#F1F1F1',
                },
              }}>
                <Typography sx={{
                  width: '100%', height: '100%', p: 1,
                  '&:hover, &:focus': {
                    backgroundColor: '#684FDD',
                    color: '#F1F1F1',
                  },
                }}>
                  {title}
                </Typography>
              </Option>
            ))}
        </Select>
       
        <Button
          variant="outlined"
          className={css.btnGradient}
          onClick={handleRestSearch}
            sx={{
            ml:1,
            color: '#f1f1f1',
            '&:hover': {
              background:
                'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
            },
          }}
        >
          {t('resetFilters')}
        </Button>
        </Box>
        <Box>
          <Button onClick={() => setModalOpen(!modalOpen)} className={css.btnGradient}
            variant="outlined"
            sx={{
              display: 'flex',
              color: '#F1F1F1',
              '&:hover': {
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
              },
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
              <path
                fill="#F1F1F1"
                d="M18 13.5h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 1 1 0-2h5v-5a1 1 0 0 1 2 0v5h5a1 1 0 0 1 0 2Z"
              />
            </svg>
            {t('addJar')}
          </Button>
        </Box>
      </Box> 
      <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
        }}
      >
        <thead>
          <tr>
            <th style={{ color: '#F1F1F1' }}>{t('jar')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_turnover')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_dayLimit')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_monthLimit')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('cardTableLimit')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_tableDailySF')}
            </th>
            <th style={{ color: '#F1F1F1' }}>{t('card_tableMonthSF')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_bank')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_accessibility')}</th>
            <th style={{ color: '#F1F1F1' }}>{t('card_table_actions')}</th>
          </tr>
        </thead>
        <tbody>
          {cards && cards.filter(card => !card.archivated).map((card: Terminal) => (
              <CardItemQAC
                card={card}
                key={card.id}
                handleDelete={handleDeleteCard}
                handleUpdate={handleUpdateCard}
                handleEditCard={handleEditCard}
                banks={banks}
              />
            ))}
        </tbody>
      </Table>
      {pageCount > 1 && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
        >
          <ReactPaginate
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={t('pagination_prev')}
            nextLabel={t('pagination_next')}
            containerClassName={'pagination'}
            pageLinkClassName={'page-number'}
            previousLinkClassName={'page-number'}
            nextLinkClassName={'page-number'}
            activeLinkClassName={'active'}
          />
        </Box>
      )}

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(!modalOpen);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: (isMobile && '80%') || '40%',
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            maxHeight: '80vh',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <IconButton
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
            sx={{ marginLeft: 'auto' }}
          >
            <IconComponent paths="m10 11.9 7.5 7.5a1.3 1.3 0 0 0 2-1.9L11.8 10l7.5-7.5a1.3 1.3 0 0 0-1.9-2L10 8.2 2.5.6a1.3 1.3 0 0 0-2 1.9L8.2 10 .6 17.5a1.3 1.3 0 1 0 1.9 2l7.5-7.6Z" />
          </IconButton>
          <Typography level="h3" sx={{ textAlign: 'center', mb: '48px', color: '#CED4DA' }}>
            {t('terminalModalHeader')}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
              <input
                {...register('jar_link')}
              className={`${css.input} ${!isFocused && watch('jar_link') && watch('jar_link')?.length > 10 ? css.valid : !isFocused && watch('jar_link') && watch('jar_link')?.length <= 10 ? css.invalid : ''}`}
                name="jar_link"
                id="jar_link"
                autoFocus
              placeholder={t('jarModalLink')}
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
              />
  
            <Input
              {...register('label')}
              name="label"
              id="label"
              placeholder={t('card_modal_lebel')}
              sx={{
                '--Input-focusedThickness': '0', mt: 2, '--Input-minHeight': '56px', '--Input-radius': '6px',
                borderBottom:
                  watch('label', '')?.length >= 2 ? '1px solid rgba(24, 232, 6, 1)' : '',  }}
            />
            <Error errors={errors} name="label" />
            <Typography sx={{ mt: 2 }} level="body-md">
              {t('cardModalDailyLimit')}
            </Typography>
            <Input
              {...register('daily_transaction_limit')}
              name="daily_transaction_limit"
              id="daily_transaction_limit"
              defaultValue={dailyTransactionLimit}
              type="number"
              slotProps={{
                input: {
                  min: 1,
                },
              }}
              placeholder={t('cardModalDailyLimit')}
              sx={{
                mt: 2,
                '--Input-focusedThickness': '0',
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                borderBottom:
                  errors.daily_transaction_limit ? '1px solid rgba(24, 232, 6, 1)' : '1px solid rgba(24, 232, 6, 1)',
              }}
            />
            <Error errors={errors} name="daily_transaction_limit" />
            <FormControl>
              <FormLabel sx={{ m: '16px 0 0 0', pl: 1, color: '#9fa6ad' }}>
                {t('card_modal_monthLimit')}
              </FormLabel>
              <Input
                {...register('limit_by_month', {
                  pattern: {
                    value: phonePattern,
                    message: t('validation_numbers'),
                  },
                  required: t('validation_required'),
                })}
                defaultValue={limitByMonth}
                name="limit_by_month"
                id="limit_by_month"
                placeholder={t('card_modal_monthLimit')}
                sx={{
                  '--Input-minHeight': '56px', '--Input-radius': '6px',
                  '--Input-focusedThickness': '0',
                  borderBottom: errors.limit_by_month
                    ? '1px solid rgba(235, 87, 87, 1)'
                    : '1px solid rgba(24, 232, 6, 1)',
}}
              />
              <Error errors={errors} name="limit_by_month" />
            </FormControl>
            <FormControl>
              <FormLabel sx={{ m: '16px 0 0 0', pl: 1, color: '#9fa6ad' }}>
                {t('card_modal_dayLimit')}
              </FormLabel>
              <Input
                {...register('limit_by_the_day', {
                  pattern: {
                    value: phonePattern,
                    message: t('validation_numbers'),
                  },
                  required: t('validation_required'),
                })}
                name="limit_by_the_day"
                defaultValue={limitByDay}
                id="limit_by_the_day"
                placeholder={t('card_modal_dayLimit')}
                sx={{
                  '--Input-minHeight': '56px', '--Input-radius': '6px',
                  '--Input-focusedThickness': '0',
                  borderBottom:
                    parseFloat(watch('limit_by_the_day')?.toString()) >
                      parseFloat(watch('limit_by_month')?.toString())
                      ? '1px solid rgba(235, 87, 87, 1)'
                      : '1px solid rgba(24, 232, 6, 1)',
}}
              />
              <Error errors={errors} name="limit_by_the_day" />
            </FormControl>

            <Controller
              control={control}
              rules={{
                required: t('validation_required'),
              }}
              name="bank"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value !== undefined ? value : ''}
                  placeholder={t('card_modal_bank')}
                  onChange={(_event: React.SyntheticEvent | null, newValue: string | null) =>
                    onChange(newValue)
                  }
                  sx={{
                    mt: 2,
                    height: '56px',
                    '--Input-minHeight': '56px',
                    '--Input-radius': '6px',
                    borderBottom: value ? '1px solid rgba(24, 232, 6, 1);' : '',
                  }}
                >
                  {banks &&
                    banks.map(({ title, id }) => (
                      <Option key={id} value={id}>
                        {title}
                      </Option>
                    ))}
                </Select>
              )}
            />

            <Error errors={errors} name="bank" />

            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'flex-end', mt: 2 }}>
              <Typography level="body-lg">{t('card_modal_accessibility')}</Typography>
              <Controller
                control={control}
                name="accessibility"
                defaultValue={false}

                render={({ field: { onChange, value } }) => (
                  <Switch
                    size="md"
                    variant="solid"
                    // defaultValue={false}
                    value={value}
                    sx={(theme: Theme) => ({
                      '--Switch-thumbSize': '27px',
                      '--Switch-trackWidth': '51px',
                      '--Switch-trackHeight': '31px',
                      '--Switch-trackBackground': theme.vars.palette.background.level3,
                      [`& .${switchClasses.thumb}`]: {
                        transition: 'width 0.2s, left 0.2s',
                      },
                      '&:hover': {
                        '--Switch-trackBackground': theme.vars.palette.background.level3,
                      },
                      '&:active': {
                        '--Switch-thumbWidth': '32px',
                      },
                      [`&.${switchClasses.checked}`]: {
                        '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                        '&:hover': {
                          '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                        },
                      },
                    })}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onChange={(
                      event: { target: { checked: boolean } },
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      _newValue: string
                    ) => {
                      onChange(event.target.checked);
                    }}
                  />
                )}
              />
            </Box>
            <Button
              type="submit"
              variant="outlined"
              className="btnGradient"
              sx={{
                mt:2,
                width: '100%',
                p: '17px 0',
                fontSize: '14px',
                color: '#f1f1f1',
                '&:hover': {
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                },
              }}
              disabled={!isValid}
            >
              {t('addJar')}
            </Button>
          </form>
        </Sheet>
      </Modal>
      {archItems?.length > 0 && (
        <>
          <Typography level="h2" sx={{ p: '20px 0 ', fontWeight: 600, }}>{t('archivedTerminals')}</Typography>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
              '--TableCell-paddingY': '4px',
              '--TableCell-paddingX': '8px',
            }}
          >
            <thead>
              <tr>
                <th style={{ width: '16%', color: '#F1F1F1' }}>{t('jar')}</th>
                <th style={{ width: '15%', color: '#F1F1F1' }}>{t('card_table_turnover')}</th>
                <th style={{ width: '10%', color: '#F1F1F1' }}>{t('card_table_dayLimit')}</th>
                <th style={{ width: '12%', color: '#F1F1F1' }}>{t('card_table_monthLimit')}</th>
                <th style={{ width: '12%', color: '#F1F1F1' }}>{t('card_table_bank')}</th>
                <th style={{ width: '10%', color: '#F1F1F1' }}>{t('card_table_actions')}</th>
              </tr>
            </thead>
            <tbody>
              {archItems?.map((archItems) => (
                <ArchivedTerminalItem
                  handleDelete={handleDeleteCard}
                  card={archItems}
                  key={archItems.id}
                />
              ))}
            </tbody>
          </Table>

          {pageCountArch > 1 && (
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
            >
              <ReactPaginate
                onPageChange={handleArchPageClick}
                pageCount={pageCountArch}
                previousLabel={t('pagination_prev')}
                nextLabel={t('pagination_next')}
                containerClassName={'pagination'}
                pageLinkClassName={'page-number'}
                previousLinkClassName={'page-number'}
                nextLinkClassName={'page-number'}
                activeLinkClassName={'active'}
              />
            </Box>
          )}
        </>
      )} 
    </>
  );
};

export default CardListQAC;
