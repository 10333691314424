import { io } from 'socket.io-client';

const url = process.env.REACT_APP_SOCKET_URL as string;
// const token = localStorage.getItem('persist:auth')

// let access_token
// token !== null ? access_token = JSON.parse(token) : null

// const formattedToken = access_token?.access_token
//     ? access_token.access_token.replace(/"/g, '')
//     : '';


export const socket = io(url, {
    autoConnect: false,
    // extraHeaders: { Authorization: `Bearer ${formattedToken}` }
});
