import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../redux/auth/selectors';
import { getBanks, getAllPayouts } from '../services/operators';
import { getPageSizes } from '../utils/pagination';
import { Banks, Payout } from '../utils/types';
export const usePayoutsHistory = ({
  userId,
  filterQueries,
}: {
  userId?: number | null;
  filterQueries: {
    searchQuery: string;
    searchBank: string | null;
    searchStatus: string | null;
    searchByPocket: string | null;
    searchByChangedBy: string | null;
    sDate: null | Date | string;
    fDate: null | Date | string;
    sort: null | string;
  };
}) => {
  const [notifications, setNotifications] = useState<Payout[] | []>([]);
  const [banks, setBanks] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number | null>(null);
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getPageSizes();
        setPageSize(data);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);

  const pageCount = Math.ceil(count / (pageSize || 10));
  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1);
  };
  const { access_token } = useSelector(getGlobalUser);
  useEffect(() => {
    if (!userId || pageSize === null) {
      return;
    }
    const fetch = async () => {
      const data = await getAllPayouts(userId, currentPage, pageSize, filterQueries);
      const filteredNotifications = data?.payouts.filter(
        (notification: Payout) => notification
      );
      setNotifications(filteredNotifications);
      setCount(data?.count);
    };
    fetch();
  }, [currentPage, filterQueries, pageSize]);

  useEffect(() => {
    const fetch = async () => {
      const allBanks = await getBanks(access_token);

      setBanks(allBanks?.filter((bank: Banks) => bank?.title !== 'Other bank'));
    };
    fetch();
  }, []);

  return { notifications, count, handlePageClick, pageCount, banks };
};
