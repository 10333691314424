import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Box, Button, Modal, Sheet, Typography } from '@mui/joy';
import { Dispatch, SetStateAction } from 'react';
import { DateRange, RangeKeyDict } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { DatePickerStateItem, itemType } from '../../utils/types';
export type ItemRange = {
  selection: itemType;
};

type handleChangeType = (item: ItemRange) => void;

const DatePickerModal = ({
  handleChange,
  open,
  setOpen,
  datePickerState,
  handleAddDeadline,
}: {
  handleChange: handleChangeType;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  datePickerState: DatePickerStateItem[];
  handleAddDeadline: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(!open);
      }}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: 'md',
          p: 4,
          boxShadow: 'lg',
        }}
      >
        <Typography level="title-lg" textAlign={'center'} sx={{ mb: 2 }}>
          {t('search_period')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(rangesByKey: RangeKeyDict) => handleChange(rangesByKey as ItemRange)}
            moveRangeOnFirstSelection={false}
            ranges={datePickerState}
            maxDate={new Date()}
          />
        </Box>
        <Button
          color="primary"
          sx={{
            display: 'block', m: '20px auto 0 auto', p: '6px 44px', background:
              'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
            '&:hover': {
              background:
                'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
            }, }}
          onClick={handleAddDeadline}
        >
          {t('settings_save_btn')}
        </Button>
      </Sheet>
    </Modal>
  );
};

export default DatePickerModal;
