import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/joy';
import useWidth from '../../hooks/useWidth';
import { getCardForPay } from '../../services/merchant';

import { StatusForAll } from '../../utils/status';
// import { useMerchantData } from '../../hooks/useMerchantData';
import { Banks, Cards } from '../../utils/types';
import { toast } from 'react-toastify';
import ChoiceAmount from './Steps/ChoiceAmount';
import ChoiceBank from './Steps/ChoiceBank';
import Transaction from './Steps/Transaction';
import Stepper from './Steps/StepperNav';
import { useTranslation } from 'react-i18next';
import { getAllBanks } from '../../services/operators';

const CustomVerticalStepper = ({ api_key }: { api_key: string | null }) => {
  const { t } = useTranslation();

  const steps = [t('stepper_first'), t('stepper_second'), t('stepper_third')];

  const [activeStep, setActiveStep] = useState(0);
  const [amount, setAmount] = useState('');
  const [confirmTrans, setConfirmTrans] = useState(false);
  const [card, setCard] = useState<Cards | null>(null);
  const [status, setStatus] = useState(StatusForAll.init);
  const [currentBank, setCurrentBank] = useState<Banks | null>(null);
  const [timerCloseCard, setTimerCloseCard] = useState<null | number>(null);
  const [sig, setSig] = useState(null);
  const [closeCardDate, setCloseCardDate] = useState<null | string>(null);
  const { isMobile } = useWidth();
  // const { banks } = useMerchantData();
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const allBanks = await getAllBanks(api_key);

      setBanks(allBanks);
    };
    fetch();
  }, []);

  const handleMakePay = async (bank: Banks | null) => {
    if (bank) {
      try {
        setStatus(StatusForAll.loading);
        const newCard = await getCardForPay(Number(amount), api_key, bank.id);
        setCard(newCard?.card);
        setTimerCloseCard(newCard?.timer.duration);
        setCloseCardDate(newCard?.timer.timer);
        setSig(newCard?.transaction_id);
        setStatus(StatusForAll?.success);
      } catch {
        setCard(null);
        setStatus(StatusForAll.success);
      }
    } else {
      toast.error(t('card_modal_bank'));
    }
  };

  const handleNext = (bank: Banks | null) => {
    if (Number(amount) <= 29999 && Number(amount) >= 100) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      toast.error(t('amount_toastMin'));
    }
    if (activeStep === 1) {
      handleMakePay(bank);
    }
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    if (activeStep === 2) {
      setCard(null);
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (timerCloseCard) {
      timer = setTimeout(() => {
        handleBack();
      }, timerCloseCard);
    }

    return () => clearTimeout(timer);
  }, [timerCloseCard]);

  return (
    <Box sx={{ display: 'flex', p: '40px 0' }}>
      <Box>
        {!isMobile && (
          <Stepper
            steps={steps}
            activeStep={activeStep}
            amount={amount}
            currentBank={currentBank}
          />
        )}
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', width: '80%', justifyContent: 'center' }}
      >
        <>
          {activeStep === 0 && <ChoiceAmount setAmount={setAmount} />}
          {activeStep === 1 && (
            <ChoiceBank
              banks={banks}
              currentBank={currentBank}
              setCurrentBank={setCurrentBank}
              handleNext={handleNext}
            />
          )}
          {activeStep === 2 && (
            <Transaction
              timer={closeCardDate}
              status={status}
              card={card}
              amount={amount}
              confirmTrans={confirmTrans}
              setConfirmTrans={setConfirmTrans}
              setTimerCloseCard={setTimerCloseCard}
              sig={sig}
              api_key={api_key}
            />
          )}
        </>
        {!confirmTrans && (
          <Box sx={{ display: 'flex', gap: '10px', m: '0 auto' }}>
            <Button onClick={handleBack} disabled={activeStep === 0}>
              {t('stepper_btn_back')}
            </Button>
            {activeStep !== 1 && (
              <Button onClick={() => handleNext(null)} disabled={activeStep === steps.length - 1}>
                {t('stepper_btn_next')}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CustomVerticalStepper;
