import React from 'react';
import { Typography, Box } from '@mui/joy';
import useWidth from '../../hooks/useWidth';
const TextComponent = ({ text, info, bgColor }: { text: string; info: string, bgColor: string }) => {
  const { isMobile } = useWidth();
  return (
    <Box sx={{ display: 'flex' }}>
      <span
        style={{
          display: 'block',
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          backgroundColor: bgColor,
          marginRight: '8px',
        }}
      ></span>
      <Box>
        <Typography sx={{ color: '#f1f1f1', fontWeight: 600, fontSize: isMobile ? '14px' : '18px' }}>
          {text}
        </Typography>
        <Typography sx={{ color: '#CED4DA', fontSize: '14px' }}>{info}</Typography>
      </Box>
    </Box>
  );
};

export default TextComponent;
