import { Box } from '@mui/joy';
import React from 'react';

import { Transactions } from '../../../utils/types';
import ActiveItem from './ActiveItem';

const ActiveList = ({ list }: { list: Transactions[] }) => {

  return (
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      {list.map((listItem: Transactions) => (
        <ActiveItem listItem={listItem} key={listItem.id} />
      ))}
    </Box>
  );
};

export default ActiveList;
