
export const boxStyle = {
    position: 'absolute',
    background: 'linear-gradient(135deg, rgba(104, 79, 221, 0.3) 0%, rgba(47, 146, 172, 0.3) 100%)',
    borderRadius: '8px',
    padding: '8px 32px',
    display: 'flex',
    alignItems: 'center',
    width:'153px',
    right: '-8px',
    bottom: '34px',
};

export const triangleStyle = {
    content: "''",
    position: 'absolute',
    bottom: '-8px',
    left: '50%',
    marginLeft: '-8px',
    width: '0',
    height: '0',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTop: '8px solid rgba(104, 79, 221, 0.3)',
};