import { Box } from '@mui/joy';
import React, { useEffect, useState } from 'react';

import { Payout } from '../../../utils/types';
import PendingItem from './PendingItem';

const PendingList = ({ list }: { list: Payout[] }) => {
    const [listPayouts, setListTransactions] = useState(list);

    useEffect(() => {
        setListTransactions(list);
    }, [list]);

    return (
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            {listPayouts.map((listItem: Payout) => (
                <PendingItem listItem={listItem} key={listItem.id} />
            ))}
        </Box>
    );
};

export default PendingList;