import React from 'react';
import { Typography, Box } from '@mui/joy';
import DonutBalance from './DonutBalance';

const BalanceBox = ({
  balance,
  info,
  amount,
  backgroundColor,
  textColor,
  bgColor,
  series,
  options,
}: {
  balance: string;
  info: string;
  amount: string;
  backgroundColor: string;
  textColor: string;
  bgColor: string;
  series: number[];
  options: ApexCharts.ApexOptions;
}) => {

  return (
    <Box
      sx={{
        borderRadius: '16px',
        padding: '16px 20px',
        background: 'rgba(17, 19, 23, 1)',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            color: textColor,
            padding: '8px',
            background: bgColor,
            borderRadius: '6px',
          }}
        >
          {balance}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              display: 'block',
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              backgroundColor: backgroundColor,
              marginRight: '8px',
            }}
          ></span>
          <Typography>{info}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '28px',
        }}
      >
        <Typography level="h4" sx={{ color: '#F1F1F1' }}>
          {amount}
        </Typography>
        <DonutBalance series={series} options={options} />
      </Box>
    </Box>
  );
};
export default BalanceBox;
