import {
    Box,
    Button,
    Sheet,
    Modal,
    Input,
    Typography,
    IconButton,
    FormControl,
    ListItem,
    List,
} from '@mui/joy';
import { t } from "i18next";
import React from 'react';
import { useState } from "react";
import { toast } from "react-toastify";
import useWidth from '../../hooks/useWidth';
import { addNewTag, removeTag, updateTag } from '../../services/operators';
import { Tags } from '../../utils/types';
import { AiFillEdit, AiFillDelete } from 'react-icons/ai';
import { IoMdClose } from "react-icons/io";
import { useCardList } from '../../hooks';

interface ModalAddTagProps {
    isAddTagModalOpen: boolean;
    setIsAddTagModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalAddTag: React.FC<ModalAddTagProps> = ({ isAddTagModalOpen, setIsAddTagModalOpen }) => {
    const [newTag, setNewTag] = useState('');
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [selectedTag, setSelectedTag] = useState<number>();
    const { isMobile } = useWidth();
    const [isEditing, setIsEditing] = useState(false);
    const [editedTag, setEditedTag] = useState('');
    const { tags, updateTags } = useCardList({
        userId: null,
        searchQuery: '',
        searchBank: '',
        setSearchQuery: null,
        setSearchBank: null,
        setSearchTag: null,
        searchTag: '',
        pageSize: 1,
    })

    const handleCreateNewTag = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const checkTag = (tags as Tags[])?.some((tag) => tag.title === newTag);
        if (!checkTag) {
            try {
                await addNewTag(newTag);
                toast.success(t('toast_createTag'));
                await updateTags();
            } catch (error) {
                console.log(error);
            }
            setNewTag('');
            return;
        } else {
            toast.error(t('toast_createTag_error'));
        }
    }

    const handleDeleteTag = async () => {
        if (selectedTag) {
            try {
                await removeTag(selectedTag);
                await updateTags();
            } catch (error) {
                console.log(error)
            }
        }
        setIsConfirmationModalOpen(false);
    }

    const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && selectedTag) {
            try {
                await updateTag(selectedTag, editedTag);
                await updateTags();
                setIsEditing(false);
            } catch (error) {
                console.error(error);
            }
        }
    };

    return (
        <>
            <Modal open={isAddTagModalOpen} onClose={() => setIsAddTagModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Sheet
                    variant="outlined"
                    sx={{
                        width: (isMobile && '80%') || '40%',
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <Typography level="h4" sx={{ textAlign: 'center', mb: 2 }}>
                        {t('card_modal_addtag')}
                    </Typography>
                    <List>
                        {tags &&
                            tags.map(({ title, id }) => (
                                <ListItem key={id} value={id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <>
                                        {isEditing && selectedTag === id ? (
                                            <Input
                                                sx={{borderRadius: '8px',
                                                    '&::before': {
                                                        display: 'none',
                                                    },
                                                    '&:focus-within': {
                                                        outline: '1px solid rgba(104, 79, 221, 0.8)',
                                                    },
                                                    '--Input-focused': 0,

                                                }}
                                                value={editedTag}
                                                onChange={(e) => setEditedTag(e.target.value)}
                                                autoFocus
                                                onKeyPress={handleKeyPress}
                                            />
                                        ) : (
                                            title
                                        )}
                                        <Box>
                                            {isEditing && selectedTag === id ? (
                                                <IconButton aria-label="close" title="close"
                                                >
                                                    <IoMdClose style={{ width: '16px', height: '16px' }} onClick={() => {
                                                        setIsEditing(false);
                                                    }} />
                                                </IconButton>) : (<IconButton aria-label="edit" title="edit"
                                                    onClick={() => {
                                                        setSelectedTag(id);
                                                        setIsEditing(true);
                                                        setEditedTag(title);
                                                    }}
                                                >
                                                    <AiFillEdit style={{ width: '16px', height: '16px' }} />
                                                </IconButton>)}
                                            <IconButton
                                                aria-label="delete" title="delete"
                                                onClick={() => {
                                                    setSelectedTag(id);
                                                    setIsConfirmationModalOpen(!isConfirmationModalOpen);
                                                }} >

                                                <AiFillDelete style={{ width: '16px', height: '16px', marginLeft: '6px' }} />
                                            </IconButton>
                                        </Box>

                                    </>
                                </ListItem>
                            ))}
                    </List>
                    <form style={{ textAlign: 'center' }}
                        onSubmit={handleCreateNewTag}
                    >
                        <FormControl>
                            <Input sx={{
                                mb: 3, borderRadius: '8px',
                                '&::before': {
                                    display: 'none',
                                },
                                '&:focus-within': {
                                    outline: '1px solid rgba(104, 79, 221, 0.8)',
                                },
                                '--Input-focused': 0,

                            }} value={newTag}
                                onChange={(e) => setNewTag(e.target.value)}
                                autoFocus
                                required
                                placeholder={t('card_modal_tag')} />
                        </FormControl>
                        <Button sx={{
                            display: 'block', m: '0 auto',
                            background:
                                'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                            '&:hover': {
                                background:
                                    'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                            },
                        }} type="submit">{t('card_modal_addtag')}</Button>
                    </form>
                </Sheet>
            </Modal>

            {isConfirmationModalOpen && (
                <Modal
                    open={isConfirmationModalOpen}
                    onClose={() => {
                        setIsConfirmationModalOpen(!isConfirmationModalOpen);
                    }}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Sheet
                        variant="outlined"
                        sx={{
                            width: '300px',
                            borderRadius: 'md',
                            p: 4,
                            boxShadow: 'lg',
                        }}
                    >
                        <Typography level="title-lg" textAlign={'center'} sx={{ mb: 2 }}>
                            {t('tag_modal_title')}
                        </Typography>
                        <Button
                            sx={{
                                display: 'block',
                                m: '20px auto 0 auto', p: '6px 44px',
                                background:
                                    'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                                '&:hover': {
                                    background:
                                        'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                                },
                            }}
                            onClick={handleDeleteTag}
                        >
                            {t('transaction_modal_btnYes')}
                        </Button>
                        <Button
                            sx={{
                                display: 'block',
                                m: '20px auto 0 auto', p: '6px 44px',
                                background:
                                    'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                                '&:hover': {
                                    background:
                                        'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                                },
                            }}
                            onClick={() => setIsConfirmationModalOpen(false)}
                        >
                            {t('transaction_modal_btnNo')}
                        </Button>
                    </Sheet>
                </Modal>
            )}
        </>
    )
}

export default ModalAddTag;