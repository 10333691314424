import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import ActiveTrans from '../../components/ActiveTrans';
import ActiveList from '../../components/ActiveTrans/MobileList/ActiveList';
import { useTranslation } from 'react-i18next';
import useWidth from '../../hooks/useWidth';
import { getAllTrans } from '../../services/operators';
import { initialFilterState } from '../History/History';
import PendingTransaction from '../../components/ActiveTrans/PendingTransaction';
import PendingList from '../../components/ActiveTrans/MobileList/PendingLIst';
import { Transactions } from '../../utils/types';

const Active = () => {
  const { isMobile } = useWidth();
  const { t } = useTranslation();
  const { activeTransactions, user } = useSelector(getGlobalUser);
  const [activeItems, setActiveItems] = useState<Transactions[]>([]);
  const [pendingTransaction, setPendingTransaction] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllTrans(user.id as number, null, null, {
        ...initialFilterState,
        searchStatus: 'PROCESSING',
      });
      if (data) {
        const items = data.transactions.reverse();
        setActiveItems(items);
      }
    };
    fetch();
  }, [activeTransactions]);

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllTrans(user.id as number, null, null, {
        ...initialFilterState,
        searchStatus: 'PENDING',
      });
      if (data) {
        setPendingTransaction(data.transactions);
      }
    };
    fetch();
  }, [activeTransactions]);

  return (
    <Box sx={{ mb: 3, pl: isMobile ? 0 : 3, width: '100%' }}>
      <Typography level="h2" sx={{ m: isMobile ? '20px 0  10px 0' : '56px 0 40px 0 ', fontWeight: 600, fontSize: isMobile ? '24px': '28px' }}>
        {t('active_header')}
      </Typography>
      <ActiveList list={activeItems} />
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          display: {
            xs: 'none',
            sm: 'flex',
          },
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '120px',
              md: '160px',
            },
          },
        }}
      >
        <ActiveTrans notifications={activeItems} />
      </Box>
      <Typography level="h2" sx={{ m: isMobile ? '20px 0  10px 0' : '80px 0  40px 0', fontWeight: 600, fontSize: isMobile ? '24px' : '28px' }}>
        {t('active_header_pending')}
      </Typography>
      <PendingList list={pendingTransaction} />
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          display: {
            xs: 'none',
            sm: 'flex',
          },
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '120px',
              md: '160px',
            },
          },
        }}
      >
        <PendingTransaction notifications={pendingTransaction} />
      </Box>
    </Box>
  );
};

export default Active;
