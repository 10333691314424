import {
  Box,
  Button,
  Input,
  Modal,
  Option,
  Select,
  Sheet,
  Switch,
  Typography,
  FormControl,
  switchClasses,
  Theme,
} from '@mui/joy';

import React, { Dispatch, SetStateAction } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useWidth from '../../hooks/useWidth';
import { phonePattern } from '../../utils/patterns';
import { Banks, Terminal } from '../../utils/types';
import Error from '../Error';

interface IFormInput {
  bank: string;
  limit_by_month: number;
  limit_by_the_day: number;
  label: string;
  accessibility: boolean;
  daily_transaction_limit: number;
}

const EditJarModal = ({
  editOpen,
  setEditOpen,
  card,
  handleEditCard,
  banks,
}: {
  editOpen: boolean;
  setEditOpen: Dispatch<SetStateAction<boolean>>;
  card: Terminal;
  banks: Banks[];
  handleEditCard: (
    jars: {
      id: number;
      bank_id: number;
      monthly_limit: number;
      dailyLimit: number;
      label: string;
      is_active: boolean;
      daily_transaction_limit: number;
    }[]
  ) => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWidth();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    criteriaMode: 'all',
  });

  const onSubmit = ({
    bank,
    limit_by_month,
    limit_by_the_day,
    label,
    accessibility,
    daily_transaction_limit
  }: IFormInput) => {
    const cardForUpdate = {
      id: card.id,
      bank_id: Number(bank),
      monthly_limit: Number(limit_by_month),
      dailyLimit: Number(limit_by_the_day),
      label: label,
      is_active: accessibility,
      daily_transaction_limit: Number(daily_transaction_limit),
    };
    handleEditCard([cardForUpdate]);
    setEditOpen(!editOpen);
    reset();
  };

  return (
    <Modal
      open={editOpen}
      onClose={() => {
        setEditOpen(!editOpen);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: (isMobile && '80%') || '40%',
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Typography level="h4" sx={{ textAlign: 'center', mb: 2 }}>
          {t('terminalModalEdit')}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography sx={{ mt: 2 }} level="body-md">
            {t('card_modal_lebel')}
          </Typography>
          <Input
            {...register('label')}
            defaultValue={card.label}
            name="label"
            id="label"
            placeholder={t('card_modal_lebel')}
            sx={{
              mt: 1,
              '--Input-focusedThickness': '0',
              '--Input-minHeight': '56px',
              '--Input-radius': '6px',
              borderBottom: watch('label', '')?.length >= 2 ? '1px solid rgba(24, 232, 6, 1)' : '',
            }}
          />
          <Error errors={errors} name="label" />
          <Typography sx={{ mt: 2 }} level="body-md">
            {t('cardModalDailyLimit')}
          </Typography>
          <Input
            {...register('daily_transaction_limit')}
            name="daily_transaction_limit"
            id="daily_transaction_limit"
            defaultValue={card.daily_transaction_limit}
            type="number"
            slotProps={{
              input: {
                min: 1,
              },
            }}
            placeholder={t('cardModalDailyLimit')}
            sx={{
              mt: 2,
              '--Input-focusedThickness': '0',
              '--Input-minHeight': '56px',
              '--Input-radius': '6px',
              borderBottom:
                errors.daily_transaction_limit ? '1px solid rgba(24, 232, 6, 1)' : '1px solid rgba(24, 232, 6, 1)',
            }}
          />
          <Error errors={errors} name="daily_transaction_limit" />
          <FormControl sx={{ width: '100%', mt: 2 }}>
            <Typography level="body-md">{t('card_modal_monthLimit')}</Typography>
            <Input
              {...register('limit_by_month', {
                pattern: {
                  value: phonePattern,
                  message: t('validation_numbers'),
                },
              })}
              defaultValue={card.month_limit}
              name="limit_by_month"
              id="limit_by_month"
              placeholder={t('card_modal_monthLimit')}
              sx={{
                '--Input-focusedThickness': '0',
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                width: '100%',
                borderBottom: errors.limit_by_month
                  ? '1px solid rgba(235, 87, 87, 1)'
                  : '1px solid rgba(24, 232, 6, 1)',
              }}
            />
            <Error errors={errors} name="limit_by_month" />
          </FormControl>
          <FormControl sx={{ width: '100%', mt: 2 }}>
            <Typography level="body-md">{t('card_modal_dayLimit')}</Typography>
            <Input
              {...register('limit_by_the_day', {
                pattern: {
                  value: phonePattern,
                  message: t('validation_numbers'),
                },
              })}
              defaultValue={card.daily_limit}
              name="limit_by_the_day"
              id="limit_by_the_day"
              placeholder={t('card_modal_dayLimit')}
              sx={{
                '--Input-focusedThickness': '0',
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                width: '100%',
                borderBottom:
                  parseFloat(watch('limit_by_the_day')?.toString()) >
                  parseFloat(watch('limit_by_month')?.toString())
                    ? '1px solid rgba(235, 87, 87, 1)'
                    : '1px solid rgba(24, 232, 6, 1)',
              }}
            />
            <Error errors={errors} name="limit_by_the_day" />
          </FormControl>
          <Typography sx={{ mt: 2 }} level="body-md">
            {t('card_modal_bank')}
          </Typography>
          <Controller
            control={control}
            name="bank"
            defaultValue={card.bank.bank.id.toString()}
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder={t('card_modal_bank')}
                defaultValue={card.bank.bank.id.toString()}
                onChange={(_event: React.SyntheticEvent | null, newValue: string | null) =>
                  onChange(newValue)
                }
                value={value}
                sx={{
                  '--Input-focusedThickness': '0',
                  height: '56px',
                  '--Input-radius': '6px',
                  borderBottom: value ? '1px solid rgba(24, 232, 6, 1);' : 'inherit',
                }}
              >
                {banks &&
                  banks.map(({ title, id }) => (
                    <Option key={id} value={id.toString()}>
                      {title}
                    </Option>
                  ))}
              </Select>
            )}
          />
          <Error errors={errors} name="bank" />
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'flex-end', mt: 2 }}>
            <Typography level="body-lg">{t('card_modal_accessibility')}</Typography>
            <Controller
              control={control}
              name="accessibility"
              defaultValue={card.is_active}
              render={({ field: { onChange, value } }) => (
                <Switch
                  size="md"
                  variant="solid"
                  defaultValue={card.is_active}
                  value={value}
                  sx={(theme: Theme) => ({
                    '--Switch-thumbSize': '27px',
                    '--Switch-trackWidth': '51px',
                    '--Switch-trackHeight': '31px',
                    '--Switch-trackBackground': theme.vars.palette.background.level3,
                    [`& .${switchClasses.thumb}`]: {
                      transition: 'width 0.2s, left 0.2s',
                    },
                    '&:hover': {
                      '--Switch-trackBackground': theme.vars.palette.background.level3,
                    },
                    '&:active': {
                      '--Switch-thumbWidth': '32px',
                    },
                    [`&.${switchClasses.checked}`]: {
                      '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                      '&:hover': {
                        '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                      },
                    },
                  })}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  onChange={(
                    event: { target: { checked: boolean } },
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    _newValue: string
                  ) => {
                    onChange(event.target.checked);
                  }}
                />
              )}
            />
          </Box>
          <Button
            type="submit"
            variant="outlined"
            className="btnGradient"
            sx={{
              mt: 2,
              width: '100%',
              p: '17px 0',
              fontSize: '14px',
              color: '#f1f1f1',
              '&:hover': {
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
              },
            }}
          >
            {t('settings_save_btn').toUpperCase()}
          </Button>
        </form>
      </Sheet>
    </Modal>
  );
};

export default EditJarModal;
