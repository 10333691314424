import { createSlice } from '@reduxjs/toolkit';
import { TronTransactionAttributes } from './types';
import { getBalanceAndTransactionsThunk } from './thunks';

export const operatorBalanceAndTransactionsSlice = createSlice({
  name: 'operatorBalanceAndTransactions',
  initialState: { loading: false } as OperatorBalanceAndTransactionsState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getBalanceAndTransactionsThunk.pending, state => {
      state.loading = true;
    });
    builder.addCase(getBalanceAndTransactionsThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.balance = action.payload.balance;
      state.transactions = action.payload.transactions;
      // TODO: dispatch updating balance on current user in auth slice
    });
    builder.addCase(getBalanceAndTransactionsThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export type OperatorBalanceAndTransactionsState = {
  loading: boolean;
  error?: string;
  balance?: number;
  transactions?: TronTransactionAttributes[];
};

export default operatorBalanceAndTransactionsSlice.reducer;
