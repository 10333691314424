import React, { useEffect, useState } from 'react';
import { Button } from '@mui/joy';
import classes from './Settings.module.css';
import api from '../../services/axiosConfig';

const TokenList = ({token, handleDeleteMonoTokenApi}: {token: string, handleDeleteMonoTokenApi: (token: string)=> void}) => {
  const [cards, setCards] = useState([]);

  const fetchCards = async () => {
    const { data } = await api.post('account/operator/mono/mapped/cards', {
      token: token
    });
    setCards(data && data.cards);
  };

  useEffect(() => {
    const fetch = async () => {
      const allBanks = await fetchCards();
        console.log('allBanks ===>', allBanks);
    };
    fetch();
  }, []);

  return  (
    <div className={classes.box}
         style={{ display: 'flex', justifyContent: 'space-between' }}>
      <>
        <div>{token}</div>
        <div>
          {cards && cards.map((e: { MonoMapping_last4digit: string })=> {
            return <p key={e.MonoMapping_last4digit} style={{ color: '#f1f1f1', textAlign: 'center' }}>**** **** **** {e.MonoMapping_last4digit}</p>;
          })}
        </div>
        <Button
          onClick={() => handleDeleteMonoTokenApi(token)}
          sx={{ marginLeft: '8px' }}
        >
          Delete
        </Button>
      </>
    </div>
  )

};

export default TokenList;
