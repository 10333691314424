import { Box, Stack, Typography, Divider } from '@mui/joy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useWidth from '../../../hooks/useWidth';
import { formatDateText } from '../../../utils/datePickerFormat';
import { Stats } from '../../../utils/types';

const Turnovers = ({
  stats,
  filterQueries,
}: {
  stats: Stats | null;
  filterQueries: {
    sDate: string;
    fDate: string;
  };
}) => {
  const { isMobile } = useWidth();
  const { t } = useTranslation();
  const totalSumUsdt =
    (Number(stats?.acqStatistic.sumOfRewardUsdt) || 0) +
    (Number(stats?.statistic.sumOfRewardUsdt) || 0);

  const totalSumUah =
    (Number(stats?.acqStatistic.clearSumUAH) || 0) +
    (Number(stats?.statistic.clearSumUAH) || 0);
  return (
    <Box
      sx={{
        backgroundColor: '#111317',
        borderRadius: '16px',
        width: isMobile ? '100%' : '480px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px 24px',
          backgroundColor: '#18191A',
          borderRadius: '16px 16px 0 0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: isMobile ? 'wrap' : 'nowrap',
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
            <path
              fill="url(#a)"
              d="M24.9 14c0-.1-.1-.3-.3-.3H23l-.2.2-2.6 5.3H20l-2.7-5.3a.3.3 0 0 0-.2-.2h-1.9c0 .1-.1.3 0 .4l3.2 6h-1.8c-.1 0-.2.1-.2.3v.8c0 .1 0 .3.2.3H19v1.2h-2.4c-.1 0-.2 0-.2.2v.9c0 .1 0 .2.2.2H19v2c0 .1 0 .3.2.3h1.6l.2-.3v-2h2.4c.2 0 .3-.1.3-.2v-.9c0-.1-.1-.2-.3-.2H21v-1.3h2.4c.2 0 .3 0 .3-.2v-.8c0-.2-.1-.3-.3-.3h-1.8l3.3-6V14Zm-16 5.8a11 11 0 0 1 19.8-6.6L27 14.4a.3.3 0 0 0 .1.4l4.9 1.2c.2 0 .3 0 .3-.2v-5c0-.3-.2-.4-.4-.3l-1.5 1.2a13.4 13.4 0 0 0-24 8.1.3.3 0 0 0 .3.2h1.9l.2-.2Zm24.3.2h-1.9l-.2.2a11 11 0 0 1-19.8 6.6l1.6-1.2a.3.3 0 0 0-.1-.4L7.9 24c-.2 0-.3 0-.3.2v5c0 .3.2.4.4.3l1.5-1.2a13.4 13.4 0 0 0 23.9-8.2.2.2 0 0 0-.2-.1Z"
            />
            <defs>
              <linearGradient
                id="a"
                x1="6.9"
                x2="33.4"
                y1="22"
                y2="22"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#684FDD" />
                <stop offset="1" stopColor="#2F92AC" />
              </linearGradient>
            </defs>
          </svg>
          <Typography
            level="h4"
            sx={{ color: '#F1F1F1', fontSize: isMobile ? '16px' : '18px', fontWeight: 600 }}
          >
            {t('dashboard_turnovers_header')}
          </Typography>
        </Box>

        <Typography
          sx={{ color: '#CED4DA', fontSize: '14px', fontWeight: 400 }}
        >
          {(filterQueries.sDate &&
            filterQueries.fDate &&
            `${formatDateText(filterQueries.sDate)} - ${formatDateText(filterQueries.fDate)}`) ||
            t('search_period')}{' '}
        </Typography>
      </Box>

      <Box sx={{ display: 'block', gap: '10px', p: 3 }}>
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography
            level="title-sm"
            sx={{ color: '#CED4DA', fontSize: '16px', fontWeight: 400 }}
          >{`${t('dashboard_turnovers_total')} USDT`}</Typography>
          <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: '20px', fontWeight: 600 }}>
            {totalSumUsdt.toFixed(2) || 0} USDT
          </Typography>
        </Stack>
        <Divider />

        <Stack spacing={2} sx={{ mt: 2 }}>
          <Typography
            level="title-sm"
            sx={{ color: '#CED4DA', fontSize: '16px', fontWeight: 400 }}
          >{`${t('dashboard_turnovers_total')} UAH`}</Typography>
          <Typography level="h3" sx={{ color: '#F1F1F1', fontSize: '20px', fontWeight: 600 }}>
            {totalSumUah.toFixed(2) || 0} &#8372;
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default Turnovers;
