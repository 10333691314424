import { Button, Modal, Sheet, Typography } from '@mui/joy';
import React, { Dispatch, SetStateAction } from 'react';
import useWidth from '../../hooks/useWidth';

const DeleteCardModal = ({
  deleteOpen,
  setDeleteOpen,
  handleDeleteCard,
  textBtn,
  title,
}: {
  deleteOpen: boolean;
  setDeleteOpen: Dispatch<SetStateAction<boolean>>;
  handleDeleteCard: () => void;
    textBtn: string;
    title: string;
}) => {
  const { isMobile } = useWidth();
  return (
    <Modal
      open={deleteOpen}
      onClose={() => {
        setDeleteOpen(!deleteOpen);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: (isMobile && '80%') || '40%',
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <Typography level="body-lg" sx={{ textAlign: 'center', mb: 3 }}>
          {title}
        </Typography>
        <Button
          sx={{
            display: 'block', m: '0 auto',
            background:
              'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
            '&:hover': {
              background:
                'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
            },
          }}
          type="button"
          onClick={handleDeleteCard}
        >
          {textBtn}
        </Button>
      </Sheet>
    </Modal>
  );
};

export default DeleteCardModal;
