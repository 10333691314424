export const triangleStyle = {
    position: 'absolute',
    left: '-15px',
    top: '50%',
    transform: 'translateY(-50%)',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '10px 15px 10px 0',
    borderColor: 'transparent rgba(104, 79, 221, 1) transparent transparent',
};
export const boxStyle = {
    position: 'absolute',
    background: 'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    left: '143px',
    top: '-20px',
};

export const boxStyleM = {
    position: 'absolute',
    background: 'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    left: '135px',
    top: '-20px',
    width: '120px',
};