import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface IIcon extends SvgIconProps {
    paths: string | string[]; 
    viewBox?: string;
    fill?: string;
}

const IconComponent = ({ paths, viewBox , fill, ...props }: IIcon) => {
    return (
        <SvgIcon viewBox={viewBox} {...props} fill={fill}>
            {Array.isArray(paths) ? (
                paths.map((path, index) => <path key={index} d={path} />)
            ) : (
                <path d={paths} />
            )}
        </SvgIcon>
    );
}

export default IconComponent;