import { Table, Typography } from '@mui/joy';
import React from 'react';
import { Withdrawals } from '../../../utils/types';
import WithdrawalItem from './Item';
import { useTranslation } from 'react-i18next';

interface IWithdrawalItems {
  items: Withdrawals[];
  cancelWithdrawal: (id: number) => Promise<void>;
}
const WithdrawalItems: React.FC<IWithdrawalItems> = ({ items, cancelWithdrawal }) => {
  const { t } = useTranslation();
  return (
    <>
      <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
        }}
      >
        <thead>
          <tr>
            <th>{t('id')}</th>
            <th style={{ textAlign: 'center' }}>{t('value')}</th>
            <th style={{ textAlign: 'center' }}>{t('withdrawalStatus')}</th>
            <th>{t('dateAndTime')}</th>
            <th style={{ textAlign: 'center' }}>{t('card_table_actions')}</th>
          </tr>
        </thead>
        <tbody>
          {items && items.map((item: Withdrawals) => <WithdrawalItem key={item.id} item={item} cancelWithdrawal={cancelWithdrawal} />)}
        </tbody>
      </Table>
      {!items && <Typography sx={{ mt: 2, pl: 2 }}>{t('not_found')}</Typography>}
    </>
  );
};
export default WithdrawalItems;
