import {
  Box,
  Button,
  Modal,
  Sheet,
  Typography,
  Input,
  FormControl,
  FormLabel,
  Select,
  Option,
  Switch,
  IconButton,
  switchClasses,
  Theme,
} from '@mui/joy';

import React, { useEffect, useState } from 'react';
import css from '../CardList.module.css';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../../redux/auth/selectors';
import { Terminal } from '../../../utils/types';

import CardItemMobile from '../Mobile/CartItemMobile';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Error from '../../Error';
import { phonePattern } from '../../../utils/patterns';
import ReactPaginate from 'react-paginate';
import { useJar } from '../../../hooks/useJar';
import IconComponent from '../../IconComponent/IconComponent';
import { getConstants } from '../../../services/operators';

interface IFormInput {
  jar_link: string;
  bank: string;
  limit_by_month: number;
  limit_by_the_day: number;
  label: string;
  accessibility: boolean;
  daily_transaction_limit: number;
}

const CardListMobileQAC = () => {
  const { user } = useSelector(getGlobalUser);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [pageSizeConst, setPageSizeConst] = useState<number | null>(null);
  const [limitByMonth, setLimitByMonth] = useState('');
  const [limitByDay, setLimitByDay] = useState('');
  const [dailyTransactionLimit, setDailyTransactionLimit] = useState('');
  const {
    count,
    cards,
    banks,
    handleAddCart,
    handleDeleteCard,
    handleUpdateCard,
    handlePageClick,
    handleEditCard,
    archItems,
    countArch,
    handleArchPageClick
  } = useJar({
    userId: user.id,
    searchBank: '',
    setSearchBank: null,
    pageSize: pageSizeConst,
  });
  const pageCount = Math.ceil(count / (pageSizeConst || 10));
  const pageCountArch = Math.ceil(countArch / (pageSizeConst || 10));
  const [isFocused, setIsFocused] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<IFormInput>({
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<IFormInput> = ({
    jar_link,
    accessibility,
    bank,
    limit_by_month,
    limit_by_the_day,
    label,
    daily_transaction_limit,
  }: {
    jar_link: string;
    accessibility: boolean;
    bank: string | number;
    limit_by_month: number;
    limit_by_the_day: number;
    label: string;
    daily_transaction_limit: number;
  }) => {
    handleAddCart({
      jar_link,
      bank: parseFloat(bank as string),
      accessibility: (accessibility && accessibility) || false,
      label,
      limit_by_the_day,
      limit_by_month,
      daily_transaction_limit,
    });
    setModalOpen(!modalOpen);
    reset();
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const constants = await getConstants();
        if (constants) {
          setPageSizeConst(Number(constants.DEFAULT_PAGINATION));
          setLimitByMonth(constants.MONTH_LIMITS_FOR_TERMINALS);
          setLimitByDay(constants.DAY_LIMITS_FOR_TERMINALS);
          setDailyTransactionLimit(constants.ACQUIRING_TRANSACTION_DAILY_LIMIT);
        }   
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);
  return (
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      <Button
        className={css.btnGradient}
        onClick={() => setModalOpen(!modalOpen)}
        variant="outlined"
        sx={{
          mt: 2,
          p: '7px 10px',
          fontSize: '14px',
          color: '#f1f1f1',
          '&:hover': {
            background:
              'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
          },
        }}
      >
        {' '}
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
          <path
            fill="#F1F1F1"
            d="M18 13.5h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 1 1 0-2h5v-5a1 1 0 0 1 2 0v5h5a1 1 0 0 1 0 2Z"
          />
        </svg>
        {t('addJar')}
      </Button>
      {cards.map((listItem: Terminal) => (
        <CardItemMobile
          key={listItem.id}
          listItem={listItem}
          handleUpdate={handleUpdateCard}
          banks={banks}
          handleEditCard={handleEditCard}
          handleDelete={handleDeleteCard}
        />
      ))}
      {pageCount > 1 && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
        >
          <ReactPaginate
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={t('pagination_prev')}
            nextLabel={t('pagination_next')}
            containerClassName={'pagination'}
            pageLinkClassName={'page-number'}
            previousLinkClassName={'page-number'}
            nextLinkClassName={'page-number'}
            activeLinkClassName={'active'}
          />
        </Box>
      )}
      {archItems.map((listItem: Terminal) => (
        <CardItemMobile
          key={listItem.id}
          listItem={listItem}
          handleUpdate={handleUpdateCard}
          banks={banks}
          handleEditCard={handleEditCard}
          handleDelete={handleDeleteCard}
        />
      ))}
      {pageCountArch > 1 && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
        >
          <ReactPaginate
            onPageChange={handleArchPageClick}
            pageCount={pageCountArch}
            previousLabel={t('pagination_prev')}
            nextLabel={t('pagination_next')}
            containerClassName={'pagination'}
            pageLinkClassName={'page-number'}
            previousLinkClassName={'page-number'}
            nextLinkClassName={'page-number'}
            activeLinkClassName={'active'}
          />
        </Box>
      )}

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(!modalOpen);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: '80%',
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            maxHeight: '80vh',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <IconButton
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
            sx={{ marginLeft: 'auto' }}
          >
            <IconComponent paths="m10 11.9 7.5 7.5a1.3 1.3 0 0 0 2-1.9L11.8 10l7.5-7.5a1.3 1.3 0 0 0-1.9-2L10 8.2 2.5.6a1.3 1.3 0 0 0-2 1.9L8.2 10 .6 17.5a1.3 1.3 0 1 0 1.9 2l7.5-7.6Z" />
          </IconButton>
          <Typography level="h4" sx={{ textAlign: 'center', mb: 2 }}>
            {t('terminalModalHeader')}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              {...register('jar_link')}
              className={`${css.input} ${
                !isFocused && watch('jar_link') && watch('jar_link').length > 10
                  ? css.valid
                  : !isFocused && watch('jar_link') && watch('jar_link').length <= 10
                  ? css.invalid
                  : ''
              }`}
              name="jar_link"
              id="jar_link"
              autoFocus
              placeholder={t('jarModalLink')}
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
            />
            <Input
              {...register('label')}
              name="label"
              id="label"
              placeholder={t('card_modal_lebel')}
              sx={{
                '--Input-focusedThickness': '0',
                mt: 2,
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                borderBottom:
                  watch('label', '')?.length >= 2 ? '1px solid rgba(24, 232, 6, 1)' : '',
              }}
            />
            <Error errors={errors} name="label" />
            <Box sx={{ mt: 2 }}>
              <Typography level="body-md">
                {t('cardModalDailyLimit')}
              </Typography>
              <Input
                {...register('daily_transaction_limit')}
                name="daily_transaction_limit"
                id="daily_transaction_limit"
                defaultValue={dailyTransactionLimit}
                type="number"
                placeholder={t('cardModalDailyLimit')}
                slotProps={{
                  input: {
                    min: 1,
                  },
                }}
                sx={{
                  mt: 2,
                  '--Input-focusedThickness': '0',
                  '--Input-minHeight': '56px',
                  '--Input-radius': '6px',
                  borderBottom:
                    errors.daily_transaction_limit ? '1px solid rgba(24, 232, 6, 1)' : '1px solid rgba(24, 232, 6, 1)',
                }}
              />
              <Error errors={errors} name="daily_transaction_limit" />
            </Box>
            <FormControl>
              <FormLabel sx={{ m: '16px 0 0 0', pl: 1, color: '#9fa6ad' }}>
                {t('card_modal_monthLimit')}
              </FormLabel>
              <Input
                {...register('limit_by_month', {
                  pattern: {
                    value: phonePattern,
                    message: t('validation_numbers'),
                  },
                  required: t('validation_required'),
                })}
                defaultValue={limitByMonth}
                name="limit_by_month"
                id="limit_by_month"
                placeholder={t('card_modal_monthLimit')}
                sx={{
                  '--Input-minHeight': '48px',
                  '--Input-radius': '6px',
                  '--Input-focusedThickness': '0',
                  borderBottom: errors.limit_by_month
                    ? '1px solid rgba(235, 87, 87, 1)'
                    : '1px solid rgba(24, 232, 6, 1)',
                }}
              />
              <Error errors={errors} name="limit_by_month" />
            </FormControl>
            <FormControl>
              <FormLabel sx={{ m: '16px 0 0 0', pl: 1, color: '#9fa6ad' }}>
                {t('card_modal_dayLimit')}
              </FormLabel>
              <Input
                {...register('limit_by_the_day', {
                  pattern: {
                    value: phonePattern,
                    message: t('validation_numbers'),
                  },
                  required: t('validation_required'),
                })}
                name="limit_by_the_day"
                defaultValue={limitByDay}
                id="limit_by_the_day"
                placeholder={t('card_modal_dayLimit')}
                sx={{
                  '--Input-minHeight': '48px',
                  '--Input-radius': '6px',
                  '--Input-focusedThickness': '0',

                  borderBottom:
                    parseFloat(watch('limit_by_the_day')?.toString()) >
                    parseFloat(watch('limit_by_month')?.toString())
                      ? '1px solid rgba(235, 87, 87, 1)'
                      : '1px solid rgba(24, 232, 6, 1)',
                }}
              />
              <Error errors={errors} name="limit_by_the_day" />
            </FormControl>

            <Controller
              control={control}
              rules={{
                required: t('validation_required'),
              }}
              name="bank"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value !== undefined ? value : ''}
                  placeholder={t('card_modal_bank')}
                  onChange={(_event: React.SyntheticEvent | null, newValue: string | null) =>
                    onChange(newValue)
                  }
                  sx={{
                    mt: 2,
                    height: '48px',
                    '--Input-minHeight': '48px',
                    '--Input-radius': '6px',
                    borderBottom: value ? '1px solid rgba(24, 232, 6, 1);' : '',
                  }}
                >
                  {banks &&
                    banks.map(({ title, id }) => (
                      <Option key={id} value={id}>
                        {title}
                      </Option>
                    ))}
                </Select>
              )}
            />

            <Error errors={errors} name="bank" />

            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'flex-end', mt: 2 }}>
              <Typography level="body-lg">{t('card_modal_accessibility')}</Typography>
              <Controller
                control={control}
                name="accessibility"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    size="sm"
                    variant="solid"
                    defaultValue={false}
                    value={value}
                    sx={(theme: Theme) => ({
                      '--Switch-thumbSize': '20px',
                      '--Switch-trackWidth': '46px',
                      '--Switch-trackHeight': '25px',
                      '--Switch-trackBackground': theme.vars.palette.background.level3,
                      [`& .${switchClasses.thumb}`]: {
                        transition: 'width 0.2s, left 0.2s',
                      },
                      '&:hover': {
                        '--Switch-trackBackground': theme.vars.palette.background.level3,
                      },
                      '&:active': {
                        '--Switch-thumbWidth': '25px',
                      },
                      [`&.${switchClasses.checked}`]: {
                        '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                        '&:hover': {
                          '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                        },
                      },
                    })}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onChange={(
                      event: { target: { checked: boolean } },
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      _newValue: string
                    ) => {
                      onChange(event.target.checked);
                    }}
                  />
                )}
              />
            </Box>
            <Button
              type="submit"
              variant="outlined"
              className="btnGradient"
              sx={{
                mt: 2,
                width: '100%',
                p: '17px 0',
                fontSize: '14px',
                color: '#f1f1f1',
                '&:hover': {
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                },
              }}
              disabled={!isValid}
            >
              {t('addJar')}
            </Button>
          </form>
        </Sheet>
      </Modal>
    </Box>
  );
};

export default CardListMobileQAC;
