import React from 'react';
import useWidth from '../../hooks/useWidth';
import {  Box } from "@mui/joy";

import Wallet from '../../components/Wallet/Wallet';
const MyWallet = () => {
    const { isMobile } = useWidth();
    return (
        <Box sx={{ mb: 3, pl: isMobile ? 0 : 3, width: '100%' }}>
           <Wallet/>
        </Box>
    );
};

export default MyWallet;