import React, { Dispatch, SetStateAction } from 'react';
import { Box, Typography, ListItem, List } from '@mui/joy';
import useWidth from '../../../hooks/useWidth';
import { Banks } from '../../../utils/types';
import { useTranslation } from 'react-i18next';

const ChoiceBank = ({
  setCurrentBank,
  currentBank,
  handleNext,
  banks,
}: {
  setCurrentBank: Dispatch<SetStateAction<Banks | null>>;
  currentBank: Banks | null;
  handleNext: (bank: Banks) => void;
  banks: Banks[];
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWidth();

  const handleChooseBank = (bank: Banks) => {
    setCurrentBank(bank);
    handleNext(bank);
  };

  return (
    <>
      <Box
        sx={{
          width: (isMobile && '100%') || '60%',
          m: '20px auto',
        }}
      >
        <Typography level={(isMobile && 'title-md') || 'h3'} sx={{ textAlign: 'center' }}>
          {t('select_bank_header')}
        </Typography>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '10px',
            // width: '400px',
          }}
        >
          {banks?.map((bank: Banks) => (
            <ListItem
              key={bank.id}
              onClick={() => handleChooseBank(bank)}
              sx={{
                width: '140px',
                p: 2,
                borderRadius: '12px',
                bgcolor:
                  (currentBank?.title === bank.title && 'var( --accent-light)') || 'transparent',
                '&:hover': { bgcolor: 'var( --accent-light)', borderRadius: '12px' },
              }}
            >
              <img
                style={{ maxHeight: '80px', objectFit: 'cover', objectPosition: 'center' }}
                src={bank.image}
                alt={bank.title}
                width={'100%'}
              />
            </ListItem>
          ))}
        </List>
        {currentBank && (
          <Typography level="title-sm" sx={{ textAlign: 'center' }}>
            {t('select_bank_description', { currentBank: currentBank.title })}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default ChoiceBank;
