import React from 'react';
import { Typography, Box, Button, Sheet, Modal, IconButton } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import useWidth from '../../hooks/useWidth';
import IconComponent from '../IconComponent/IconComponent';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import css from './Wallet.module.css';

const ModalTopUp = ({
  openModal,
  toggleOpenModal,
  onSubmit,
  amount,
  handleChange,
  disabled,
  handleRemoveFrozenBalance,
  isValidTopUp,
  isInvalidTopUp,
  minAmounTopup,
}: {
  openModal: boolean;
  toggleOpenModal: () => void;
  onSubmit: () => void;
  amount: string;
  handleChange: (value: string) => void;
  disabled: boolean;
  handleRemoveFrozenBalance: () => void;
  isValidTopUp: boolean;
  isInvalidTopUp: boolean;
  minAmounTopup:string
}) => {
  const { user } = useSelector(getGlobalUser);
  const { t } = useTranslation();
  const { isMobile } = useWidth();
  return (
    <Modal
      open={openModal}
      onClose={toggleOpenModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'transparent',
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: (isMobile && '80%') || '600px',
          borderRadius: '16px',
          padding: '32px 40px',
          boxShadow: '4px 2px 64px 0 rgba(69, 69, 69, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          border: 'transparent',
        }}
      >
        <IconButton onClick={toggleOpenModal} sx={{ marginLeft: 'auto' }}>
          <IconComponent paths="m10 11.9 7.5 7.5a1.3 1.3 0 0 0 2-1.9L11.8 10l7.5-7.5a1.3 1.3 0 0 0-1.9-2L10 8.2 2.5.6a1.3 1.3 0 0 0-2 1.9L8.2 10 .6 17.5a1.3 1.3 0 1 0 1.9 2l7.5-7.6Z" />
        </IconButton>
        <Typography
          level='h4'
          sx={{
            textAlign: 'center',
            mb: 1,
            color: '#F1F1F1',
          }}
        >
          {t('sidebar_modal_header')}
        </Typography>
        <Typography
          level="body-xs"
          sx={{ textAlign: 'center', mb: 2, fontSize: '16px', color: 'rgba(173, 181, 189, 1)' }}
        >
          {t('sidebar_modal_description', {minAmount: minAmounTopup})}
        </Typography>
        <Typography
          level='h4'
          sx={{
            textAlign: 'center',
            mb: 3,
            color: 'rgba(148, 126, 254, 1)',
          }}
        >
          {t('sidebar_modal_deposit', { deposit: Number(user.deposit).toFixed(2) })}
        </Typography>
        <form onSubmit={onSubmit}>
          <input
            value={amount}
            onChange={(e: { target: { value: string } }) => handleChange(e.target.value)}
            id="amount"
            name="amount"
            type="number"
            min={1}
            placeholder={t('sidebarModalPlaceholder')}
            className={`${css.input} ${isValidTopUp ? css.valid : ''} ${isInvalidTopUp ? css.invalid : ''
              }`}
            autoComplete="amount"
            autoFocus
          />

          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              type="submit"
              sx={{
                width: (isMobile && '100%') || '300px',
                padding: '8px',
                background: disabled
                  ? 'rgba(73, 80, 87, 1)'
                  : 'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                color: disabled ? 'rgba(173, 181, 189, 1)' : '#FFF',
                '&:hover': {
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  color: '#f1f1f1',
                },
              }}
              disabled={disabled}
            >
              {t('sidebar_modal_btn')}
            </Button>

            <Button
              className={css.btnGradient}
              sx={{
                width: (isMobile && '100%') || '300px',
                color: '#fff',
                padding: '8px',
                '&:hover': {
                  background:
                    'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  color: '#f1f1f1',
                },
              }}
              variant="outlined"
              type="button"
              onClick={handleRemoveFrozenBalance}
            >
              {isMobile ? t('return') : t('sidebar_modal_btnVoid')}
            </Button>
          </Box>
        </form>
      </Sheet>
    </Modal>
  );
};

export default ModalTopUp;
