import React from 'react';
import ReactPaginate from 'react-paginate';
import { Box } from '@mui/joy';
import { useWithdrawals } from '../../hooks/useWithDrawals';
import { useTranslation } from 'react-i18next';
import WithdrawalItems from '../../components/Withdrawal/Items';

const Withdrawals = () => {
  const { t } = useTranslation();
  const { items, pageCount, handlePageClick, cancelWithdrawal } = useWithdrawals();
  return (
    <Box sx={{ mb: 3 }}>
      <WithdrawalItems items={items} cancelWithdrawal={cancelWithdrawal} />
      {pageCount > 1 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            mt: 2,
          }}
        >
          <ReactPaginate
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={t('pagination_prev')}
            nextLabel={t('pagination_next')}
            containerClassName={'pagination'}
            pageLinkClassName={'page-number'}
            previousLinkClassName={'page-number'}
            nextLinkClassName={'page-number'}
            activeLinkClassName={'active'}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
          />
        </Box>
      )}
    </Box>
  );
};
export default Withdrawals;
