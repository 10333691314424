import React from 'react';
import { useTimer } from 'react-timer-hook';

const Timer = ({ expiryTimestamp }: { expiryTimestamp: Date }) => {
  const { seconds, minutes } = useTimer({ expiryTimestamp });

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontSize: '100px', color: 'var(--accent-focus)' }}>
        <span>{(minutes > 9 && minutes) || `0${minutes}`}</span>:
        <span>{(seconds > 9 && seconds) || `0${seconds}`}</span>
      </div>
    </div>
  );
};

export default Timer;
