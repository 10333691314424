import { createSlice } from '@reduxjs/toolkit';

import { StatusForAll } from '../../utils/status';
import { Payout, Transactions } from '../../utils/types';
import { updateBalance ,updatebannedFrozenLimit, updateFrozenLimit, clearEmail } from './actions';

import {
  signIn,
  authenticate,
  getOperatorById,
  updateUserTransactionStatus,
  updateFrozenBalance,
  getActiveTransactions,
  resetFrozenBalance,
  updateUserApi,
  refreshToken,
  getActivePayouts,
} from './operations';
import { User } from './types';
import { mapUserFromPayload } from './mapper';

interface AuthState {
  // TODO: make it option al and init state with `user: undefined`
  user: User;
  activeTransactions: Transactions[];
  activePayouts: Payout[];
  email: string;
  access_token: string | null;
  status: string;
  isLoggedIn: boolean;
  refresh_token: string | null;
}

const initialState: AuthState = {
  user: {
    id: null,
    // TODO: delete it - there is no balance on backend
    balance: 0,
    deposit: 0,
    name: '',
    qrCode: '',
    isActiveTransaction: false,
    is_active_payout: false,
    is_payout_permitted: false,
    is_notification_enabled: false,
    frozen_limit: 0,
    banned_frozen_limit: 0,
    tax: 0,
    tg_Nick: '',
    EPocket_number: '',
    mobile_token: '',
    mono_tokens: [],
    mono_token: '',
    acquiring_tax: 0,
    manual_payout_capacity: 0,
    payout_capacity: 0,
    daily_payout: 0,
    payout_course: 0,
    payout_tax: 0,
  },
  activeTransactions: [],
  activePayouts: [],
  email: '',
  access_token: '',
  status: StatusForAll.init,
  isLoggedIn: false,
  refresh_token:'',
};

const authSlice = createSlice({
  name: 'auth',
  reducers: {
    updateDeposit(state, action: { payload: number }) {
      state.user.deposit = action.payload;
    },
  },
  initialState,
  extraReducers: builder => {
    builder
      .addCase(signIn.pending, state => {
        state.status = StatusForAll.loading;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.status = StatusForAll.success;
        state.access_token = initialState.access_token;
        state.email = action.payload.user.email;
        state.user = initialState.user;
        state.activeTransactions = initialState.activeTransactions;
        state.activePayouts = initialState.activePayouts;
        state.isLoggedIn = false;
      })
      .addCase(signIn.rejected, state => {
        state.status = StatusForAll.error;
        state.user = initialState.user;
        state.isLoggedIn = false;
      })
      .addCase(authenticate.pending, state => {
        state.status = StatusForAll.loading;
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        state.status = StatusForAll.success;
        state.isLoggedIn = !!action.payload.user.access_token;
        state.access_token = action.payload.user.access_token;
        state.user = mapUserFromPayload({ ...action.payload.user });
        state.email = action.payload.user.email;
        state.refresh_token = action.payload.user.refresh_token;
      })
      .addCase(authenticate.rejected, state => {
        state.status = StatusForAll.error;
        state.isLoggedIn = false;
      })

      .addCase(getOperatorById.pending, state => {
        state.status = StatusForAll.loading;
      })
      .addCase(getOperatorById.fulfilled, (state, { payload }) => {
        if (!payload?.user) {
          return;
        }

        // todo при логіні, 2fa і апдейті оператора дані приходять у різній формі (при апдейті є об'єкт user, в інших випадках немає)
        state.user = mapUserFromPayload({ ...payload.user });
      })
      .addCase(getOperatorById.rejected, state => {
        state.status = StatusForAll.error;
      })
      .addCase(updateUserTransactionStatus.pending, state => {
        state.status = StatusForAll.loading;
      })
      .addCase(updateUserTransactionStatus.fulfilled, (state, { payload }) => {
        state.user = mapUserFromPayload({ ...payload.user });
      })
      .addCase(updateUserTransactionStatus.rejected, state => {
        state.status = StatusForAll.error;
        state.user = {
          ...state.user,
          isActiveTransaction: false,
        };
      })
      .addCase(updateFrozenBalance.pending, state => {
        state.status = StatusForAll.loading;
      })
      .addCase(updateFrozenBalance.fulfilled, (state, action) => {
        if (action.payload.user) {
          state.user = action.payload.user && mapUserFromPayload({ ...action.payload.user });
        }
      })
      .addCase(updateFrozenBalance.rejected, state => {
        state.status = StatusForAll.error;
      })
      .addCase(resetFrozenBalance.pending, state => {
        state.status = StatusForAll.loading;
      })
      .addCase(resetFrozenBalance.fulfilled, (state, action) => {
        if (action.payload.user) {
          state.user = mapUserFromPayload({ ...action.payload.user });
        }
      })
      .addCase(resetFrozenBalance.rejected, state => {
        state.status = StatusForAll.error;
      })
      .addCase(getActiveTransactions.pending, state => {
        state.status = StatusForAll.loading;
      })
      .addCase(getActivePayouts.pending, state => {
        state.status = StatusForAll.loading;
      })
      .addCase(getActiveTransactions.fulfilled, (state, action) => {
        state.activeTransactions = action.payload;
      })
      .addCase(getActivePayouts.fulfilled, (state, action) => {
        state.activePayouts = action.payload;
      })
      .addCase(getActiveTransactions.rejected, state => {
        state.status = StatusForAll.error;
      })
      .addCase(getActivePayouts.rejected, state => {
        state.status = StatusForAll.error;
      })
      .addCase(updateUserApi.pending, state => {
        state.status = StatusForAll.loading;
      })
      .addCase(updateUserApi.fulfilled, (state, { payload }) => {
        state.user = mapUserFromPayload({ ...payload.user });
      })
      .addCase(updateUserApi.rejected, state => {
        state.status = StatusForAll.error;
        state.user = {
          ...state.user,
        };
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.access_token = action.payload.access_token;
      })
      .addCase(refreshToken.rejected, state => {
        state.isLoggedIn = false;
      }).addCase(updateBalance, (state, action) => {
        state.user.deposit = action.payload;
      }).addCase(updateFrozenLimit, (state, action) => {
        state.user.frozen_limit = action.payload;
      }).addCase(updatebannedFrozenLimit, (state, action) => {
        state.user.banned_frozen_limit = action.payload;
      }).addCase(clearEmail, (state, action) => {
        state.email = action.payload;
      });
  },
});

export default authSlice.reducer;

export const { updateDeposit } = authSlice.actions;