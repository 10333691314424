import { Box, Button, Typography } from '@mui/joy';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../utils/dateFormater';

import { getStatusTextColor } from '../../utils/getStatusColor';
import { Transactions } from '../../utils/types';
import HistoryModalQAC from './HistoryModalQAC';

const ItemQAC = ({
    notification,
    // selected,
    // setSelected,
}: {
    notification: Transactions;
    selected: readonly number[];
    setSelected: Dispatch<SetStateAction<readonly number[]>>;
}) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setOpen(inOpen);
    };
    return (
        <>
            <tr key={notification.id}>
                {/* <td style={{ textAlign: 'center', width: 120 }}>
                    <Checkbox
                        size="sm"
                        checked={selected.includes(notification.id)}
                        color={selected.includes(notification.id) ? 'primary' : undefined}
                        onChange={event => {
                            setSelected((ids: readonly number[]) =>
                                event.target.checked
                                    ? ids.concat(notification.id)
                                    : ids.filter((itemId: number) => itemId !== notification.id)
                            );
                        }}
                        slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                        sx={{ verticalAlign: 'text-bottom' }}
                    />
                </td> */}
                <td>
                    <Typography level="body-xs">
                        {(notification.label && notification.label) || t('not_found')}
                    </Typography>
                </td>
                <td>
                    <Typography level="body-xs" sx={{ overflowWrap: 'anywhere' }}>
                        {notification?.jar_link}
                    </Typography>
                    <Typography level="title-sm">{notification.operator_bank_title}</Typography>
                </td>
                <td>
                    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <Typography level="body-md" sx={{ fontWeight: 600, overflowWrap: 'anywhere' }}>
                            {Number(notification.amount).toFixed(2)}
                        </Typography>
                    </Box>
                </td>
                <td>
                    <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>{Number(notification.operatorAmount).toFixed(2)}</Typography>
                </td>
                <td>
                    <Typography level="body-md" sx={{ overflowWrap: 'anywhere' }}>{notification.merchant_bank_title && notification.merchant_bank_title || t('not_found')}</Typography>
                </td>
                <td>
                    <Typography level="body-md" sx={{ overflowWrap: 'anywhere'}}>{formatDateTime(notification.createdAt)}</Typography>
                </td>
                <td>
                    <Typography level="body-md" sx={{ overflowWrap: 'anywhere'}}>{formatDateTime(notification.updatedAt)}</Typography>
                </td>
                <td>
                    <Typography level="body-md" sx={{ color: getStatusTextColor(notification?.status) }}>
                        {notification?.status && notification.status.charAt(0).toUpperCase() + notification.status.slice(1).toLowerCase()}
                    </Typography>
                </td>
                <td>
                    <Button
                        variant="plain"
                        sx={{ display: 'block', m: 0, borderBottom: '1px solid #947EFE', color: '#947EFE', borderRadius: 0, p: 0, }}
                        onClick={toggleDrawer(true)}
                    >
                        {t('history_table_details')}
                    </Button>
                </td>
            </tr>

            <HistoryModalQAC
                id={notification.id}
                open={open}
                onClose={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            />
        </>
    );
};
export default ItemQAC;