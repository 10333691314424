import { useEffect, useState } from 'react';
import { cancelWithdrawalRequest, getWithdrawalsHistory } from '../services/operators';
import { socket } from '../socket';
import { PAGE_SIZE } from '../utils/pagination';
import { Withdrawals } from '../utils/types';

export const useWithdrawals = () => {
  const [items, setItems] = useState<Withdrawals[] | []>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const pageCount = Math.ceil(count / PAGE_SIZE);

  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected + 1);
  };
  const cancelWithdrawal = async (id: number) => {
    try {
      const resp = await cancelWithdrawalRequest(id);
      const updatedTransaction = resp.withdraw.find((transaction: Withdrawals) => transaction.id === id);

      if (updatedTransaction) {
        setItems((prev) => {
          const filteredItems = prev.filter((item) => item.id !== id);
          return [updatedTransaction, ...filteredItems];
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetch = async () => {
      
      const response = await getWithdrawalsHistory(currentPage, PAGE_SIZE); 
      setItems(response?.[0]);
      setCount(response?.[1]);
    };
    fetch();
  }, [currentPage]);

  useEffect(() => {
    socket.on('operatorWithdrawList', data => {
      setItems(data[0]);
      setCount(data[1]);
    });
    return () => {
      socket.off('operatorWithdrawList');
    };
  })
  return { items, count, handlePageClick, pageCount, cancelWithdrawal };
};
