import { Table, Typography } from '@mui/joy';
import React, { useState } from 'react';
// import Checkbox from '@mui/joy/Checkbox';
import ActivePayoutItem from './ActivePayoutItem';

import { Payout } from '../../utils/types';
import { useTranslation } from 'react-i18next';

const ActivePayouts = ({ payouts }: { payouts: Payout[] }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<readonly number[]>([]);

  return (
    <>
      <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
        }}
      >
        <thead>
          <tr>
            <th style={{ color: '#F1F1F1', fontSize: '16px', width: '20%' }}>{t('active_table_card')}</th>
            <th style={{ color: '#F1F1F1', fontSize: '16px', }}>{t('active_table_date')}</th>
            <th style={{ color: '#F1F1F1', fontSize: '16px', }}>{t('active_table_price')}</th>
            <th style={{ color: '#F1F1F1', fontSize: '16px', }}>{t('active_table_course')}</th>
            <th style={{ color: '#F1F1F1', fontSize: '16px', }}>{t('active_table_action')}</th>
          </tr>
        </thead>
        <tbody >
          {payouts &&
            payouts.map((row: Payout) => (
              <ActivePayoutItem
                key={row.id}
                payout={row}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
        </tbody>
      </Table>
      {!payouts && <Typography sx={{ mt: 2, pl: 2 }}>{t('not_found')}</Typography>}
    </>
  );
};
export default ActivePayouts;
