import React from 'react';
import Box from '@mui/joy/Box';
import { Typography } from '@mui/joy';
import CardListQAC from '../../components/Terminals/CardListQAC';
import { useTranslation } from 'react-i18next';
import CardListMobileQAC from '../../components/Terminals/Mobile/CardListMobileQAC';
import useWidth from '../../hooks/useWidth';

export const Terminals = () => {
    const { t } = useTranslation();
    const { isMobile } = useWidth();
    return (
        <Box sx={{ mb: 3, pl: isMobile ? 0 : 3, width: '100%' }}>
            <Typography level="h2" sx={{ fontWeight: 600, mt: '40px' }}>
                {t('terminalHeader')}
            </Typography>
            <CardListMobileQAC />
            <Box
                sx={{
                    py: 2,
                    display: {
                        xs: 'none',
                        sm: 'flex',
                    },
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: {
                            md: '160px',
                        },
                    },
                }}
            >
                <CardListQAC />
            </Box>
        </Box>
    );

}

export default Terminals;