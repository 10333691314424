import { Box } from '@mui/joy';
import React, { useState } from 'react';

import { Outlet } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';

import css from './Home.module.css';

const Home = () => {
  const [showLogo, setShowLogo] = useState(false);

  const handleSidebarClick = () => {
    setShowLogo(!showLogo);
  };
  return (
    <>
      <div className={css.container}>
        <Box sx={{ display: 'flex' }}>
          <Sidebar onSidebarClick={handleSidebarClick} />
          <Box sx={{ flex: 1 }}>
            <Header showLogo={showLogo} />
            <Outlet />
          </Box>
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default Home;
