import React from 'react';
import { TronTransactionAttributes } from '../../redux/operatorBalanceAndTransactions/types';
import { useTranslation } from 'react-i18next';
import { Table } from '@mui/joy';
import { getStatusTextColor } from '../../utils/getStatusColor';
export const DepositTransactionsTable = ({
  transactions,
}: {
  transactions?: TronTransactionAttributes[];
}): React.JSX.Element | null => {
  if (!transactions) {
    return null;
  }
  const { t } = useTranslation();
  return (
    <Table
      aria-labelledby="tableTitle"
      stickyHeader
      hoverRow
      sx={{
        '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
        '--Table-headerUnderlineThickness': '1px',
        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
        '--TableCell-paddingY': '4px',
        '--TableCell-paddingX': '8px',
      }}
    >
      <thead>
        <tr>
          <th className="p2p-tc">{t('id')}</th>
          <th className="p2p-tc">{t('value')}</th>
          <th className="p2p-tc">{t('withdrawalStatus')}</th>
          <th className="p2p-tc">{t('dateAndTime')}</th>
        </tr>
      </thead>
      <tbody>
        {transactions?.map(transaction => (
          <tr key={transaction.id}>
            <td style={{ overflowWrap: 'anywhere'}}>{transaction.id}</td>
            <td>{transaction.value}</td>
            <td style={{ color: getStatusTextColor(transaction.status) }}>{transaction?.status.charAt(0).toUpperCase() + transaction.status.slice(1).toLowerCase()}</td>
            <td>{new Date(transaction.block_timestamp).toLocaleString()}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
