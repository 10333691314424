import React, { Dispatch, SetStateAction } from 'react';

import { Cards } from '../../../utils/types';
import OrderExecution from '../../OrderExecution';

const Transaction = ({
  card,
  amount,
  confirmTrans,
  setConfirmTrans,
  setTimerCloseCard,
  status,
  timer,
  sig,
  api_key,
}: {
  card: Cards | null;
  amount: string;
  confirmTrans: boolean;
  setConfirmTrans: Dispatch<SetStateAction<boolean>>;
  status: string;
  timer: string | null;
  setTimerCloseCard: Dispatch<SetStateAction<number | null>>;
  sig: string | null;
  api_key: string | null;
}) => {
  return (
    <>
      <OrderExecution
        timerCloseCard={timer}
        status={status}
        card={card}
        amount={amount}
        confirmTrans={confirmTrans}
        setConfirmTrans={setConfirmTrans}
        setTimerCloseCard={setTimerCloseCard}
        sig={sig}
        api_key={api_key}
      />
    </>
  );
};
export default Transaction;
