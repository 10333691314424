import { toast } from 'react-toastify';
import api from './axiosConfig';
import i18n from 'i18next';
import { AxiosError } from 'axios';

const CALLBACK_URL = process.env.REACT_APP_CALLBACK_URL;

export const getCardForPay = async (amount: number, api_key: string | null, bank: number) => {
  try {
    const { data } = await api.post(
      `transaction/merchant/createTransaction`,
      {
        bank_id: bank,
        amount,
        callback_url: CALLBACK_URL,
        customer_id: 1,
      },
      { headers: { ' X-Api-key': api_key } }
    );

    return data;
  } catch (err: unknown) {
    const error: AxiosError<unknown> = err as AxiosError;
    console.log('error.message', error);
    toast.error(i18n.t('toast_getCardForPay'));
  }
};

export const createNotification = async (sig: string | null, api_key: string | null) => {
  try {
    const { data } = await api.post(
      'transaction/merchant/startTransaction',
      {
        transaction_id:sig,
      },
      { headers: { ' X-Api-key': api_key } }
    );
    return data;
  } catch (error) {
    toast.error(i18n.t('toast_createNotification'));
  }
};
