import { Box, Container, Typography, Input, Button } from '@mui/joy';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import css from './GoogleAuth.module.css';

import Error from '../../components/Error';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import { authenticate } from '../../redux/auth/operations';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import ToggleLanguage from '../../components/ToggleLanguage/ToggleLanguage';
import useWidth from '../../hooks/useWidth';
import Logo from '../../components/Logo/Logo';
import { clearEmail } from '../../redux/auth/actions';
interface IFormInput {
  code: string;
}

const GoogleAuth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {
    user: { qrCode },
  } = useSelector(getGlobalUser);
  const { isMobile } = useWidth();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    watch,
  } = useForm<IFormInput>({
    criteriaMode: 'all',
  });

  const { email, isLoggedIn } = useSelector(getGlobalUser);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard-operator');
    }
  }, [isLoggedIn, navigate]);
 
  useEffect(() => {
    if (email === '') {
      navigate('/login');
    }
  }, [email, navigate]);

  const onSubmit = ({ code }: { code: string }) => {
    dispatch(authenticate({ email, code }));
    reset({
      code: '',
    });
  };


  const handleExit = () => {
    dispatch(clearEmail('')) 
    navigate('/login');
  }

  return (
    <>
      <Box
        sx={{
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(6px)',
        }}
      >
        <Box>
          <Box sx={{ position: 'absolute', top: '20px', left: isMobile ? '10px' : '20px' }}>
            <Logo />
          </Box>
          {isMobile && (
            <Box sx={{ position: 'absolute', top: '20px', right: '10px' }}>
              <ToggleLanguage />
            </Box>
          )}
        </Box>
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ paddingBottom: '80px' }}>
            <Container component="main" maxWidth="xs">
              <Box
                sx={{
                  marginTop: 8,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  level="h4"
                  sx={{
                    textAlign: 'center',
                    maxWidth: '90%',
                    fontWeight: '700',
                    fontSize: isMobile ? '20px' : '24px',
                    color: '#F1F1F1',
                  }}
                >
                  {t('qr_header')}
                </Typography>
                {qrCode && (
                  <div style={{ background: 'white', padding: '16px' }}>
                    <img src={qrCode} />
                  </div>
                )}
                <Typography sx={{mt:1}} level="body-md">{email && email }</Typography>
               
                <Box component="form" onSubmit={handleSubmit(onSubmit)} className={css.form}>
                  <Input
                    {...register('code', {
                      required: t('validation_required'),
                    })}
                    id="code"
                    name="code"
                    placeholder={t('qr_code')}
                    className={css.fullInput}
                    autoComplete="code"
                    autoFocus={true}
                    sx={{
                      mt: 2,
                      borderColor: 'transparent',
                      '--Input-placeholderOpacity': 1,
                      '--Input-focusedInset': '#18191A',
                      '--Input-focusedThickness': '0',
                      backgroundColor: '#18191A',
                      '&:focus': {
                        borderBottom: '1px solid #EB5757',
                      },
                      '&:focus-within': {
                        borderBottom:
                          watch('code')?.length >= 6 ? '1px solid #18E806' : '1px solid #EB5757',
                      },
                    }}
                  />
                  <Error errors={errors} name="code" />
                  <Button
                    sx={{
                      mt: '32px',
                      p: '16px',
                      borderRadius: '8px',
                      width: '100%',
                      background: !isValid
                        ? '#495057'
                        : 'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                      color: '#f1f1f1',
                      '&:hover': {
                        background:
                          'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                      },
                    }}
                    type="submit"
                  >
                    {t('qr_btn')}
                  </Button> 
                  <Button className={css.btnGradient} variant='outlined' sx={{
                    width: '100%',
                    p: 2,
                    mt:2,
                    color: '#f1f1f1',
                    '&:hover': {
                      background:
                        'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                     
                    },
                  }}
                    type="button" onClick={handleExit}>
                    {t('sidebar_exit')}
                  </Button>
                  
                </Box>
              </Box>
            </Container>
          </div>
        </div>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          padding: isMobile ? 0 : '80px 80px 0 0',
        }}
      >
        <Box
          sx={{
            height: '100%',
            backgroundSize: '100% auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(/Illustration.png)`,
          }}
        >
          <Box sx={{ position: 'absolute', top: '20px', right: '20px' }}>
            <ToggleLanguage />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GoogleAuth;
