import {
  Box,
  Button,
  Modal,
  Sheet,
  Typography,
  Input,
  Select,
  Option,
  Switch,
  switchClasses,
  Theme,
  selectClasses,
  IconButton,
} from '@mui/joy';

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import css from '../CardList.module.css';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../../redux/auth/selectors';
import { Cards,Tags } from '../../../utils/types';
import { useCardList } from '../../../hooks';
import { useCreditCardValidator, images } from 'react-creditcard-validator';
import CardItem from './CardItem';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useWidth from '../../../hooks/useWidth';
import Error from '../../Error';
import { phonePattern } from '../../../utils/patterns';
import ReactPaginate from 'react-paginate';
import ModalAddTag from '../ModalAddTag';
import { getAllTags, getConstants } from '../../../services/operators';
import IconComponent from '../../IconComponent/IconComponent';

interface IFormInput {
  bank: string;
  tag: string;
  limit: number;
  limit_by_month: number;
  limit_by_the_day: number;
  label: string;
  accessibility: boolean;
  send_id: string;
  iban: string;
  iban_only: boolean;
  daily_transaction_limit: number;
}

const CardListMobile = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
}) => {
  const { user } = useSelector(getGlobalUser);
  const { t } = useTranslation();
  const { isMobile } = useWidth();
  const [modalOpen, setModalOpen] = useState(false);
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const [tagList, setTagList] = useState<Tags[]>([]);
  const [pageSizeConst, setPageSizeConst] = useState<number | null>(null);
  const [limitByMonth, setLimitByMonth] = useState('');
  const [limitByDay, setLimitByDay] = useState('');
  const [dailyTransactionLimit, setDailyTransactionLimit] = useState('');
  const {
    cards,
    count,
    handleUpdateCard,
    banks,
    handleEditCard,
    handleDeleteCard,
    handlePageClick,
    handleAddCart,
    expDateValidate,
    archCards,
    countArchCards,
    handleArchPageClick
  } = useCardList({
    userId: user.id,
    searchQuery,
    searchBank: '',
    setSearchQuery,
    setSearchBank: null,
    setSearchTag: null,
    searchTag: '',
    pageSize: pageSizeConst,
  });
  const pageCount = Math.ceil(count / (pageSizeConst || 10));
  const pageArchCount = Math.ceil(countArchCards / (pageSizeConst || 10));

  const {
    getCardNumberProps,
    getCardImageProps,
    meta: { erroredInputs },
  } = useCreditCardValidator({ expiryDateValidator: expDateValidate });
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<IFormInput>({
    criteriaMode: 'all',
  });
  const onSubmit: SubmitHandler<IFormInput> = ({
    accessibility,
    bank,
    tag,
    limit_by_month,
    limit_by_the_day,
    label,
    send_id,
    iban,
    iban_only,
    daily_transaction_limit,
  }: {
    accessibility: boolean;
    bank: string | number;
    tag: number | null | string;
    limit_by_month: number;
    limit_by_the_day: number;
    label: string;
    send_id: string;
    iban: string;
    iban_only: boolean;
    daily_transaction_limit: number;
  }) => {
    const { ref } = getCardNumberProps();

    if (!erroredInputs.cardNumber && ref?.current?.value) {
      handleAddCart({
        cardNumber: ref?.current?.value,
        bank: parseFloat(bank as string),
        tag: parseFloat(tag as string),
        accessibility: (accessibility && accessibility) || false,
        label,
        limit_by_the_day,
        limit_by_month,
        send_id,
        iban,
        iban_only,
        daily_transaction_limit,
      });
      setModalOpen(!modalOpen);
    } else {
      toast.error(erroredInputs.cardNumber);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const constants = await getConstants();
        if (constants) {
          setPageSizeConst(Number(constants.DEFAULT_PAGINATION));
          setLimitByMonth(constants.MONTH_LIMITS_FOR_CARDS);
          setLimitByDay(constants.DAY_LIMITS_FOR_CARDS);
          setDailyTransactionLimit(constants.TRANSACTION_DAILY_LIMIT);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getData();
  }, []);
  
  useEffect(() => {
    const getData = async () => {
      const allTags = await getAllTags();
      setTagList(allTags);
    };
    getData();
  }, [modalOpen]);
 
  return (
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      <Button onClick={() => setIsAddTagModalOpen(true)} className={css.btnGradient} variant="outlined" sx={{
        p: '7px 10px', fontSize: '14px', color: '#f1f1f1', '&:hover': {
          background:
            'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
        
        },
      }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><path fill="#F1F1F1" d="M18 13.5h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 1 1 0-2h5v-5a1 1 0 0 1 2 0v5h5a1 1 0 0 1 0 2Z" /></svg> {t('tag_addtag_btn')}
      </Button>
  
      <Button className={css.btnGradient}
        onClick={() => setModalOpen(!modalOpen)}
        variant='outlined' sx={{
          ml: 1,
          p: '7px 10px', fontSize: '14px', color: '#f1f1f1', '&:hover': {
            background:
              'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
          },
        }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none"><path fill="#F1F1F1" d="M18 13.5h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 1 1 0-2h5v-5a1 1 0 0 1 2 0v5h5a1 1 0 0 1 0 2Z" /></svg>
      
        {t('card_add_btn')}
      </Button>
      {cards.map((listItem: Cards) => (
        <CardItem
          key={listItem.id}
          listItem={listItem}
          handleUpdate={handleUpdateCard}
          banks={banks}
          tags={tagList}
          handleEditCard={handleEditCard}
          handleDelete={handleDeleteCard}
        />
      ))}
      {pageCount > 1 && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
        >
          <ReactPaginate
            onPageChange={handlePageClick}
            pageCount={pageCount}
            previousLabel={t('pagination_prev')}
            nextLabel={t('pagination_next')}
            containerClassName={'pagination'}
            pageLinkClassName={'page-number'}
            previousLinkClassName={'page-number'}
            nextLinkClassName={'page-number'}
            activeLinkClassName={'active'}
          />
        </Box>
      )}

      {archCards.map((listItem: Cards) => (
        <CardItem
          key={listItem.id}
          listItem={listItem}
          handleUpdate={handleUpdateCard}
          banks={banks}
          tags={tagList}
          handleEditCard={handleEditCard}
          handleDelete={handleDeleteCard}
        />
      ))}
      {pageArchCount > 1 && (
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
        >
          <ReactPaginate
            onPageChange={handleArchPageClick}
            pageCount={pageArchCount}
            previousLabel={t('pagination_prev')}
            nextLabel={t('pagination_next')}
            containerClassName={'pagination'}
            pageLinkClassName={'page-number'}
            previousLinkClassName={'page-number'}
            nextLinkClassName={'page-number'}
            activeLinkClassName={'active'}
          />
        </Box>
      )}

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(!modalOpen);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: (isMobile && '80%') || '40%',
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            maxHeight: '80vh',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <IconButton
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
            sx={{ marginLeft: 'auto' }}
          >
            <IconComponent paths="m10 11.9 7.5 7.5a1.3 1.3 0 0 0 2-1.9L11.8 10l7.5-7.5a1.3 1.3 0 0 0-1.9-2L10 8.2 2.5.6a1.3 1.3 0 0 0-2 1.9L8.2 10 .6 17.5a1.3 1.3 0 1 0 1.9 2l7.5-7.6Z" />
          </IconButton>
          <Typography
            level="h3"
            sx={{ textAlign: 'center', mb: '20px', fontSize: '18px', color: '#CED4DA' }}
          >
            {t('card_modal_header')}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ position: 'relative' }}>
              <svg
                {...getCardImageProps({ images })}
                style={{ position: 'absolute', top: '20px', right: 0, zIndex: 10, width: '60px' }}
              />
              <input
                className={`${css.input} ${erroredInputs.cardNumber && erroredInputs.cardNumber === 'Card number is invalid'
                    ? css.invalid
                    : erroredInputs.cardNumber === 'Card number is valid' ||
                      erroredInputs.cardNumber === undefined
                      ? css.valid
                      : ''
                  }`}
                {...getCardNumberProps()}
                name="cardNumber"
                id="cardNumber"
                autoFocus
                placeholder={t('card_modal_card')}
              />
            </Box>
            {/* <small style={{ color: 'red', paddingLeft: '10px' }}>
              {erroredInputs.cardNumber && erroredInputs.cardNumber}
            </small> */}
            <Input
              {...register('label')}
              name="label"
              id="label"
              placeholder={t('card_modal_lebel')}
              sx={{
                mt: 4,
                '--Input-focusedThickness': '0',
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                borderBottom:
                  watch('label', '')?.length >= 2 ? '1px solid rgba(24, 232, 6, 1)' : '',
              }}
            />
            <Error errors={errors} name="label" />
            <Input
              {...register('send_id')}
              name="send_id"
              id="send_id"
              placeholder={t('cardModalID')}
              sx={{
                mt: 4,
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                '--Input-focusedThickness': '0',
                borderBottom:
                  watch('send_id', '')?.length >= 2 ? '1px solid rgba(24, 232, 6, 1)' : '',
              }}
            />
            <Error errors={errors} name="send_id" />
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ mb: 1, }} level="body-md">
                {t('cardModalDailyLimit')}
              </Typography>
            <Input
              {...register('daily_transaction_limit')}
              name="daily_transaction_limit"
              id="daily_transaction_limit"
              defaultValue={dailyTransactionLimit}
              type="number"
                placeholder={t('cardModalDailyLimit')}
                slotProps={{
                  input: {
                    min: 1,
                  },
                }}
              sx={{
                mt: 4,
                '--Input-focusedThickness': '0',
                '--Input-minHeight': '56px',
                '--Input-radius': '6px',
                borderBottom:
                  errors.daily_transaction_limit ? '1px solid rgba(24, 232, 6, 1)' : '1px solid rgba(24, 232, 6, 1)',
              }}
            />
            <Error errors={errors} name="daily_transaction_limit" />
                </Box>
                <Typography sx={{ mb: 1, mt:2 }} level="body-md">
                  {t('card_modal_monthLimit')}
                </Typography>
                <Input
                  {...register('limit_by_month', {
                    pattern: {
                      value: phonePattern,
                      message: t('validation_numbers'),
                    },
                    required: t('validation_required'),
                  })}
                  defaultValue={limitByMonth}
                  name="limit_by_month"
                  id="limit_by_month"
                  placeholder={t('card_modal_monthLimit')}
                  sx={{
                    '--Input-minHeight': '56px',
                    '--Input-radius': '6px',
                    '--Input-focusedThickness': '0',
                    borderBottom: errors.limit_by_month
                      ? '1px solid rgba(235, 87, 87, 1)'
                      : '1px solid rgba(24, 232, 6, 1)',
                  }}
                />
                <Error errors={errors} name="limit_by_month" />
           
                <Typography sx={{ mb: 1 , mt:2}} level="body-md">
                  {t('card_modal_dayLimit')}
                </Typography>
                <Input
                  {...register('limit_by_the_day', {
                    pattern: {
                      value: phonePattern,
                      message: t('validation_numbers'),
                    },
                    required: t('validation_required'),
                  })}
                  className={css.input}
                  name="limit_by_the_day"
                  defaultValue={limitByDay}
                  id="limit_by_the_day"
                  placeholder={t('card_modal_dayLimit')}
                  sx={{
                    '--Input-minHeight': '56px',
                    '--Input-radius': '6px',
                    '--Input-focusedThickness': '0',
                    borderBottom:
                      parseFloat(watch('limit_by_the_day')?.toString()) >
                        parseFloat(watch('limit_by_month')?.toString())
                        ? '1px solid rgba(235, 87, 87, 1)'
                        : '1px solid rgba(24, 232, 6, 1)',
                  }}
                />
                <Error errors={errors} name="limit_by_the_day" />
            <Controller
              control={control}
              rules={{
                required: t('validation_required'),
              }}
              name="bank"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value !== undefined ? value : ''}
                  placeholder={t('card_modal_bank')}
                  onChange={(_event: React.SyntheticEvent | null, newValue: string | null) =>
                    onChange(newValue)
                  }
                  indicator={
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
                      <path
                        fill="#ADB5BD"
                        d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
                      />
                    </svg>
                  }
                  sx={{
                    [`& .${selectClasses.indicator}`]: {
                      transition: '0.2s',
                      [`&.${selectClasses.expanded}`]: {
                        transform: 'rotate(-180deg)',
                      },
                    },
                    mt: 4,
                    height: '56px',
                    '--Input-minHeight': '56px',
                    '--Input-radius': '6px',
                    borderBottom: value ? '1px solid rgba(24, 232, 6, 1);' : '',
                  }}
                >
                  {banks &&
                    banks.map(({ title, id }) => (
                      <Option key={id} value={id}>
                        {title}
                      </Option>
                    ))}
                </Select>
              )}
            />

            <Error errors={errors} name="bank" />
            <Controller
              control={control}
              name="tag"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value !== undefined ? value : ''}
                  placeholder={t('card_modal_tag')}
                  onChange={(_event: React.SyntheticEvent | null, newValue: string | null) =>
                    onChange(newValue)
                  }
                  indicator={
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
                      <path
                        fill="#ADB5BD"
                        d="M3.7 7.8a1 1 0 1 0-1.4 1.4l9 8.5a1 1 0 0 0 1.4 0l9-8.5a1 1 0 1 0-1.4-1.4L12 15.6 3.7 7.8Z"
                      />
                    </svg>
                  }
                  sx={{
                    [`& .${selectClasses.indicator}`]: {
                      transition: '0.2s',
                      [`&.${selectClasses.expanded}`]: {
                        transform: 'rotate(-180deg)',
                      },
                    },
                    mt: 4,
                    height: '56px',
                    '--Input-minHeight': '56px',
                    '--Input-radius': '6px',
                    borderBottom: value ? '1px solid rgba(24, 232, 6, 1);' : '',
                  }}
                >
                  {tagList &&
                    tagList.map(({ title, id }) => (
                      <Option key={id} value={id}>
                        {title}
                      </Option>
                    ))}
                </Select>
              )}
            />
            <Error errors={errors} name="tag" />
            <Box
              sx={{ display: 'flex', gap: '10px', alignItems: 'center', mt: '34px', mb: '40px' }}
            >
              <Typography level="body-lg" sx={{ color: '#F1F1F1' }}>
                {t('card_modal_accessibility')}
              </Typography>
              <Controller
                control={control}
                name="accessibility"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    size="md"
                    variant="solid"
                    sx={(theme: Theme) => ({
                      '--Switch-thumbSize': '27px',
                      '--Switch-trackWidth': '51px',
                      '--Switch-trackHeight': '31px',
                      '--Switch-trackBackground': theme.vars.palette.background.level3,
                      [`& .${switchClasses.thumb}`]: {
                        transition: 'width 0.2s, left 0.2s',
                      },
                      '&:hover': {
                        '--Switch-trackBackground': theme.vars.palette.background.level3,
                      },
                      '&:active': {
                        '--Switch-thumbWidth': '32px',
                      },
                      [`&.${switchClasses.checked}`]: {
                        '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                        '&:hover': {
                          '--Switch-trackBackground': 'rgba(104, 79, 221, 1)',
                        },
                      },
                    })}
                    defaultValue={false}
                    value={value}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore

                    onChange={(
                      event: { target: { checked: boolean } },
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      _newValue: string
                    ) => {
                      onChange(event.target.checked);
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Button
                type="submit"
                variant="outlined"
                className="btnGradient"
                sx={{
                  width: '100%',
                  p: '17px 0',
                  fontSize: '14px',
                  color: '#f1f1f1',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                  },
                }}
                disabled={!isValid}
              >
                {t('card_add_btn')}
              </Button>
              <Button
                type="button"
                variant="outlined"
                className="btnGradient"
                sx={{
                  width: '100%',

                  p: '17px 0',
                  fontSize: '14px',
                  '&:hover': {
                    background:
                      'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                    color: '#f1f1f1',
                  },
                }}
                onClick={() => {
                  setModalOpen(!modalOpen);
                }}
              >
                {t('withdrawalBtn')}
              </Button>
            </Box>
          </form>
        </Sheet>
      </Modal>
      {isAddTagModalOpen && (
        <ModalAddTag setIsAddTagModalOpen={setIsAddTagModalOpen} isAddTagModalOpen={isAddTagModalOpen} />
      )}
    </Box>
  );
};

export default CardListMobile;
