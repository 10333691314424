import { Box, Button, List, ListDivider, ListItem, ListItemContent, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../utils/dateFormater';
import { getStatusTextColor } from '../../../utils/getStatusColor';
import { Transactions } from '../../../utils/types';
import HistoryModalQAC from '../HistoryModalQAC';

const TransactionByIdMobQac = ({ transactionById }: { transactionById: Transactions }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(inOpen);
  };
  return (
    <List
      size="sm"
      sx={{
        position: 'relative',
        '--ListItem-paddingX': 0,
      }}
    >
      <ListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          position: 'relative',
        }}
      >
        <ListItemContent>
          <Typography level="title-md">{transactionById.label && transactionById.label}</Typography>
          <Box sx={{ mt: 2 }}>
            <Typography level="title-sm" sx={{ color: '#f1f1f1' }}>
              {t('historyTableTerminal')}
            </Typography>
            <Typography level="body-xs" sx={{ color: '#f1f1f1', overflowWrap: 'anywhere' }}>
              {transactionById.jar_link}
            </Typography>
            <Typography level="title-sm">{transactionById.operator_bank_title}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_priceUah')}</Typography>
            <Typography level="body-md">{Number(transactionById.amount).toFixed(2)}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_priceUsdt')}</Typography>
            <Typography level="body-md">{Number(transactionById.usdtAmount).toFixed(2)}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('tablePayerBank')}</Typography>
            <Typography level="body-md">
              {(transactionById.merchant_bank_title && transactionById.merchant_bank_title) ||
                t('not_found')}
            </Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_startDate')}</Typography>
            <Typography level="body-md">{formatDateTime(transactionById.createdAt)}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_endDate')}</Typography>
            <Typography level="body-md">{formatDateTime(transactionById.updatedAt)}</Typography>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Typography level="body-xs">{t('history_table_status')}</Typography>
            <Typography level="body-md" sx={{ color: getStatusTextColor(transactionById?.status) }}>
              {transactionById?.status &&
                transactionById.status.charAt(0).toUpperCase() +
                  transactionById.status.slice(1).toLowerCase()}
            </Typography>
          </Box>
          <Button
            variant="plain"
            sx={{
              display: 'block',
              position: 'absolute',
              top: 0,
              right: '3px',
              borderBottom: '1px solid #947EFE',
              color: '#947EFE',
              borderRadius: 0,
              p: 0,
            }}
            onClick={toggleDrawer(true)}
          >
            {t('history_table_details')}
          </Button>
        </ListItemContent>
      </ListItem>
      <ListDivider />
      <HistoryModalQAC
        id={transactionById.id}
        open={open}
        onClose={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      />
    </List>
  );
};

export default TransactionByIdMobQac;
