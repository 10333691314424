import React from 'react';
import { Sheet, Modal, IconButton } from '@mui/joy';
import useWidth from '../../hooks/useWidth';
import IconComponent from '../IconComponent/IconComponent';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import { QRCodeSVG } from 'qrcode.react';

const ModalTopUp = ({
    showQrCode,
    toggleQrCode
}: {
    showQrCode: boolean;
    toggleQrCode: () => void;

}) => {
    const { user } = useSelector(getGlobalUser);
    const { isMobile } = useWidth();
    return (
        <Modal
            open={showQrCode}
            onClose={toggleQrCode}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: 'transparent',
            }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    width: (isMobile && '80%') || '300px',
                    borderRadius: '16px',
                    padding: '40px',
                    boxShadow: '4px 2px 64px 0 rgba(69, 69, 69, 0.2)',
                    display: 'flex',
                    flexDirection: 'column',
                    border: 'transparent',
                    position: 'relative',
                    alignItems: 'center',
                }}
            >
                <IconButton onClick={toggleQrCode} sx={{ position: 'absolute', top: '10px', right: '10px' }}>
                    <IconComponent paths="m10 11.9 7.5 7.5a1.3 1.3 0 0 0 2-1.9L11.8 10l7.5-7.5a1.3 1.3 0 0 0-1.9-2L10 8.2 2.5.6a1.3 1.3 0 0 0-2 1.9L8.2 10 .6 17.5a1.3 1.3 0 1 0 1.9 2l7.5-7.6Z" />
                </IconButton>
                {user.deposit_wallet && (
                    <QRCodeSVG value={user.deposit_wallet} size={200} bgColor="#F1F1F1" includeMargin={true} />
                )}

            </Sheet>
        </Modal>
    );
};

export default ModalTopUp;