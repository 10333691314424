import { Button, Typography, } from '@mui/joy';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { formatDateTime } from '../../utils/dateFormater';
import { getAllPayouts, processPayoutStatus, updatePayout } from '../../services/operators';
import { useDispatch, useSelector } from 'react-redux';
import { getActivePayouts, getOperatorById } from '../../redux/auth/operations';
import { getGlobalUser } from '../../redux/auth/selectors';
import { Payout } from '../../utils/types';
import { toast } from 'react-toastify';
import { AppDispatch } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import { initialFilterState } from '../../pages/History/History';
import ModalConfirm from '../../pages/ActivePayouts/ModalConfirm';

const ActivePayoutItem = ({
  payout,
}: {
  payout: Payout;
  selected: readonly number[];
  setSelected: Dispatch<SetStateAction<readonly number[]>>;
}) => {
  const { t } = useTranslation();
  const [acceptTrans, setAcceptTrans] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [receiptNumber, setReceiptNumber] = useState('');
  const [receiptUrl, setReceiptUrl] = useState('');
  const [image, setImage] = useState<File[] | null>(null);
  const { user } = useSelector(getGlobalUser);
  const dispatch = useDispatch<AppDispatch>();

  const firstSixDigits = payout.pocket_address.slice(0, 4);
  const lastFourDigits = payout.pocket_address.slice(-3);
  const maskedPart = '*'.repeat(9);
  const cardNumber = `${firstSixDigits}${maskedPart}${lastFourDigits}`;

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setImage(filesArray);
    }
  };

  const handleAcceptPayout = async () => {
    try {
      await updatePayout(payout.id, payout?.amount, receiptNumber, receiptUrl, image);
      const fetch = async () => {
        const data = await getAllPayouts(user.id as number, null, null, {
          ...initialFilterState,
          searchStatus: 'PROCESSING',
        });
        dispatch(getActivePayouts(data.payouts));
      };
      fetch();
      dispatch(getOperatorById(user.id));
      setAcceptTrans(true);
    } catch {
      toast.error(t('toast_AcceptPayout'));
    }
  };

  const toggleOpenModal = async () => {
    if (payout.status === 'PENDING' && openModalConfirm) {
      await processPayoutStatus(payout.id);
    }
    setOpenModalConfirm(!openModalConfirm);
  };

  const handleChange = (value: string) => {
    setReceiptNumber(value);
  };

  const handleChangeReceiptUrl = (value: string) => {
    setReceiptUrl(value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleAcceptPayout();
    toggleOpenModal();
  };

  return (
    <>
      <tr>
        <td>
          <Typography level="body-md" sx={{ color: '#CED4DA', overflowWrap: 'anywhere' }}>{cardNumber}</Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{ color: '#F1F1F1', fontWeight: '14px' }}>{formatDateTime(payout.created_at.toString())}</Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{ fontWeight: 500, color: '#F1F1F1', }}>
            ***** UAH
          </Typography>
        </td>
        <td>
          <Typography level="body-md" sx={{ fontWeight: 500, color: '#F1F1F1', }}>{Number(payout.operator_course).toFixed(2)}</Typography>
        </td>
        <td>
          <Button
            variant="plain"
            //onClick={onSubmit}
            onClick={toggleOpenModal}
            disabled={(acceptTrans && true) || false}
            sx={{
              display: 'block', color: '#947EFE', fontSize: '16px', fontWeight: 500, borderBottom: '1px solid #947EFE',
              p: 0,
              borderRadius: 0,
              m: 0,
            }}
          >
            {t('active_table_confirm')}
          </Button>
        </td>
      </tr>
      <ModalConfirm
        openModal={openModalConfirm}
        toggleOpenModal={toggleOpenModal}
        onSubmit={onSubmit}
        handleChange={handleChange}
        handleChangeReceiptUrl={handleChangeReceiptUrl}
        payout={payout}
        handleChangeImage={handleChangeImage}
      />
    </>
  );
};
export default ActivePayoutItem;
