import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import ActiveTrans from '../../components/ActivePayouts';
import ActiveList from '../../components/ActivePayouts/MobileList/ActiveList';
import { useTranslation } from 'react-i18next';
import useWidth from '../../hooks/useWidth';
import { getAllPayouts, savePayoutCapacity } from '../../services/operators';
import { initialFilterState } from '../History/History';
import PendingPayout from '../../components/ActivePayouts/PendingPayout';
import PendingList from '../../components/ActivePayouts/MobileList/PendingList';
import { Payout } from '../../utils/types';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { Button, FormControl, FormHelperText, Input } from '@mui/joy';

const ActivePayouts = () => {
  const { isMobile } = useWidth();
  const { t } = useTranslation();
  const { activePayouts, user } = useSelector(getGlobalUser);
  const [activePayoutItems, setActivePayoutItems] = useState<Payout[]>([]);
  const [pendingPayouts, setPendingPayouts] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllPayouts(user.id as number, null, null, {
        ...initialFilterState,
        searchStatus: 'PROCESSING',
      });

      if (data) {
        const items = data.payouts.reverse();
        setActivePayoutItems(items);
      }
    };
    fetch();
  }, [activePayouts]);

  const [desiredCapacity, setDesiredCapacity] = useState(user.manual_payout_capacity);

  const handleCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDesiredCapacity(+event.target.value);
  };

  const maxCapacity = user.payout_capacity - user.daily_payout > 0 ? user.payout_capacity - user.daily_payout : 0;
  const course = +user.payout_course - (+user.payout_course * +user.payout_tax);


  const saveCapacity = async () => {
    if (maxCapacity > 0 && maxCapacity >= desiredCapacity) {
      await savePayoutCapacity(desiredCapacity)
    } else {
      toast.error(i18n.t('toast_capacity_set_error'));
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const data = await getAllPayouts(user.id as number, null, null, {
        ...initialFilterState,
        searchStatus: 'PENDING',
      });

      if (data) {
        setPendingPayouts(data.payouts);
      }
    };
    fetch();
  }, [activePayouts]);

  return (
    <Box sx={{ mb: 3, pl: isMobile ? 0 : 3, width: '100%' }}>
      <Typography level="h2" sx={{ p: '20px 0', fontWeight: 600, fontSize: isMobile ? '20px' : '28px' }}>
        {t('sidebar_nav_payout_settings')}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2, alignItems: 'flex-start' }}>
        <Typography sx={{ mr: isMobile ? 0 : 1, whiteSpace: 'nowrap' }}>
          {t('settings_payout_input_capacity')}
        </Typography>
        <FormControl sx={{ flex: '0 1 auto', minWidth: isMobile ? '100%' : '200px' }}>
          <Input
            placeholder={Number(user.manual_payout_capacity > 0 ? user.manual_payout_capacity : 0).toFixed(2) }
            onChange={handleCapacityChange}
            type="number"
          />
          <FormHelperText sx={{ maxWidth: 250 }}>{t('settings_payout_capacity_notification')}</FormHelperText>
        </FormControl>
        <Button
          sx={{ whiteSpace: 'nowrap', height: '40px', padding: '0 16px', minWidth: 'auto', mt: isMobile ? 1 : 0 }}
          onClick={saveCapacity}
        >
          {t('settings_save_btn')}
        </Button>
      </Box>
      <Box sx={{ display: 'flex', gap: 2, mt: isMobile ? 2 : 0 }}>
        <Typography level="h3" sx={{ p: '20px 0', fontWeight: 600 }}>
          {t('payout_course')} {Number(course).toFixed(2)}
        </Typography>
      </Box>
      <hr/>
      <Typography level="h2" sx={{ m: isMobile ? '20px 0 10px 0' : '56px 0 40px 0', fontWeight: 600, fontSize: isMobile ? '24px': '28px' }}>
        {t('active_header_payout')}
      </Typography>
      <ActiveList list={activePayoutItems} />
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          display: {
            xs: 'none',
            sm: 'flex',
          },
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '120px',
              md: '160px',
            },
          },
        }}
      >
        <ActiveTrans payouts={activePayoutItems} />
      </Box>
      <Typography level="h2" sx={{ m: isMobile ? '20px 0  10px 0' : '80px 0  40px 0', fontWeight: 600, fontSize: isMobile ? '24px' : '28px' }}>
        {t('active_header_pending_payout')}
      </Typography>
      <PendingList list={pendingPayouts} />
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          display: {
            xs: 'none',
            sm: 'flex',
          },
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '120px',
              md: '160px',
            },
          },
        }}
      >
        <PendingPayout payouts={pendingPayouts} />
      </Box>
    </Box>
  );
};

export default ActivePayouts;
