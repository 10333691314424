import React, { Dispatch, SetStateAction } from 'react';
import { Box, Typography, Input } from '@mui/joy';
import useWidth from '../../../hooks/useWidth';
import { useTranslation } from 'react-i18next';

const ChoiceAmount = ({ setAmount }: { setAmount: Dispatch<SetStateAction<string>> }) => {
  const { t } = useTranslation();
  const { isMobile } = useWidth();
  return (
    <>
      <Box sx={{ p: '60px 0' }}>
        <Typography level={(isMobile && 'title-md') || 'h3'} sx={{ textAlign: 'center' }}>
          {t('amount_header')}
        </Typography>
        <Typography level="h4" sx={{ textAlign: 'center' }}>
          {t('amount_description')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: (isMobile && 'block') || 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '20px',
          width: '60%',
          m: '20px auto',
        }}
      >
        <Input
          id="standard-basic"
          variant="soft"
          sx={{ width: '100%' }}
          onChange={(e: { target: { value: string } }) => setAmount(e.target.value)}
          placeholder={t('amount_placeholder')}
        />
      </Box>
    </>
  );
};

export default ChoiceAmount;
