import React, { useState } from 'react';
import Box from '@mui/joy/Box';
import { FormControl, Input, Sheet, Typography } from '@mui/joy';
import CardList from '../../components/CardList';
import { useTranslation } from 'react-i18next';
import CardListMobile from '../../components/CardList/MobileList/CardList';
import useWidth from '../../hooks/useWidth';

const Cards = () => {
  const { t } = useTranslation();
  const { isMobile } = useWidth();
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  return (
    <Box sx={{ mb: 3, pl: isMobile ? 0 : 3, width: '100%' }}>
      <Typography level="h2" sx={{ fontWeight: 600, mt: '40px' }}>
        {t('card_header')}
      </Typography>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: {
            xs: 'block',
            sm: 'none',
          },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          sx={{
            padding: '12px 16px 12px 48px',
            width: '100%',
            boxSizing: 'border-box',
            borderRadius: '8px',
            fontSize: '18px',
            background: " #111317 url('/icons/search.svg') no-repeat",
            backgroundPosition: '12px center',
            color: '#868E96',
            borderColor: 'transparent',
            '--Input-placeholderOpacity': 1,
            '--Input-focusedInset': 'transparent',
          }}
          placeholder={t('card_search_placeholder')}
          value={searchQuery}
          onChange={(e: { target: { value: string } }) => {
            handleSearchChange(e.target.value);
          }}
        />
      </Sheet>
      <CardListMobile searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          mt: 3,
          display: {
            xs: 'none',
            sm: 'block',
          },
          gap: 1.5,
          '& > *': {
            minWidth: {
              xs: '120px',
              md: '160px',
            },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <Input
            sx={{
              padding: '12px 16px 12px 48px',
              width: '430px',
              boxSizing: 'border-box',
              borderRadius: '8px',
              fontSize: '18px',
              background: " #111317 url('/icons/search.svg') no-repeat",
              backgroundPosition: '12px center',
              color: '#868E96',
              borderColor: 'transparent',
              '--Input-placeholderOpacity': 1,
              '--Input-focusedInset': 'transparent',
            }}
            placeholder={t('card_search_placeholder')}
            value={searchQuery}
            onChange={(e: { target: { value: string } }) => {
              handleSearchChange(e.target.value);
            }}
          />
        </FormControl>

        <CardList searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      </Box>
    </Box>
  );
};

export default Cards;
