import { Button, Typography } from '@mui/joy';
import React, {useState} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatDateTime } from '../../../utils/dateFormater';
import { getStatusTextColor } from '../../../utils/getStatusColor';
import { Withdrawals } from '../../../utils/types';
import IconComponent from '../../IconComponent/IconComponent';
import { copy } from '../../Wallet/data';
import useWidth from '../../../hooks/useWidth';

const WithdrawalItem: React.FC<{ item: Withdrawals; cancelWithdrawal: (id: number) => Promise<void> }> = ({ item, cancelWithdrawal }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const { isMobile } = useWidth();
  const handleCancelWithdrawal = async () => {
    try {
      await cancelWithdrawal(item.id);
      toast.success(t("toastCancelWithdrawalRequest"));
    } catch (error) {
      console.error(error);
    }
  };

  const status = {
    processing: 'PROCESSING'
  }

  const onCopyText = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <>
      <tr key={item.id}>
          {isCopied && (
          <Typography level="body-xs" style={{ marginLeft: '10px' , color: 'green' }}>
            {t('copied')}
          </Typography>
        )}
        <td style={{ display: 'flex', alignItems:'center' }}>
          <CopyToClipboard text={item.id} onCopy={onCopyText}>
            <IconComponent viewBox="0 0 24 24" paths={copy} />
          </CopyToClipboard>
          <Typography level="body-xs">{item.id}</Typography>
        </td>
      
     
        <td style={{ textAlign: 'center' }}>
          <Typography level="body-md">{Number(item.usdtAmount).toFixed(2)}</Typography>
        </td>
        <td style={{ textAlign: 'center' }}>
          <Typography
            level="body-md"
            sx={{ color: getStatusTextColor(item.status), overflowWrap:'anywhere'}}
          >
            {item.status && item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase()}
          </Typography>
        </td>
        <td>
          <Typography level="body-xs" sx={{overflowWrap:'anywhere'}}>{formatDateTime(item.createdAt)}</Typography>
        </td>
        <td style={{ textAlign: 'center' }}>
          {item.status === status.processing && (
            <Button sx={{
              p: isMobile ? '4px' : '',
             fontSize:isMobile ? '10px' : '',
             background: 'linear-gradient(90deg, rgba(104, 79, 221, 1), rgba(47, 146, 172, 1))', color: '#f1f1f1', 
              '&:hover': {
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
              },}}
              onClick={handleCancelWithdrawal}>{t('withdrawalBtn')}</Button>
          )}
        </td>
      </tr>
    </>
  );
};
export default WithdrawalItem;