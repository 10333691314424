import { Box, Button, List, ListDivider, ListItem, ListItemContent, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ModalConfirm from '../../../pages/Active/ModalConfirm';
import { initialFilterState } from '../../../pages/History/History';
import { getActiveTransactions, getOperatorById } from '../../../redux/auth/operations';
import { getGlobalUser } from '../../../redux/auth/selectors';
import { AppDispatch } from '../../../redux/store';
import { getAllTrans, updateTrans } from '../../../services/operators';
import { formatDateTime } from '../../../utils/dateFormater';
import { maskCreditCardNumber } from '../../../utils/formatCreditCard';
import { Transactions } from '../../../utils/types';

const ActiveItem = ({ listItem }: { listItem: Transactions }) => {
  const { t } = useTranslation();
  const [acceptTrans, setAcceptTrans] = useState(false);
  const { user } = useSelector(getGlobalUser);
  const dispatch = useDispatch<AppDispatch>();
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [amount, setAmount] = useState(listItem?.init_amount_uah);
  const handleAcceptTrans = async () => {
    try {
      await updateTrans(listItem.id, Number(amount));
      const fetch = async () => {
        const data = await getAllTrans(user.id as number, null, null, {
          ...initialFilterState,
          searchStatus: 'PROCESSING',
        });

        dispatch(getActiveTransactions(data.transactions));
      };
      fetch();
      dispatch(getOperatorById(user.id));
      setAcceptTrans(true);
    } catch {
      toast.error(t('toast_AcceptTrans'));
    }
  };
  const toggleOpenModal = () => {
    setOpenModalConfirm(!openModalConfirm);
  };
  const handleChange = (value: string) => {
    setAmount(+value);
  };

  const onSubmit = () => {
    handleAcceptTrans();
    toggleOpenModal();
  };

  return (
    <>
      <List
        size="sm"
        sx={{
          position: 'relative',
          '--ListItem-paddingX': 0,
        }}
      >
        <ListItem
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            position: 'relative',
          }}
        >
          <ListItemContent>
            <Typography level="title-md">
              {(listItem.label && listItem.label)}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography level="body-xs">{t('history_table_card')}</Typography>
              <Typography level="body-xs">{maskCreditCardNumber(listItem.pocket_address)}</Typography>
              <Typography level="title-sm">{listItem.operator_bank_title}</Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography level="body-xs">{t('history_table_startDate')}</Typography>
              <Typography level="body-md">{formatDateTime(listItem.createdAt)}</Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography level="body-xs">{t('history_table_priceUah')}</Typography>
              <Typography level="body-md">{Number(listItem.init_amount_uah).toFixed(2)}</Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography level="body-xs">{t('active_table_course')}</Typography>
              <Typography level="body-md">{Number(listItem.currentCourse).toFixed(2)}</Typography>
            </Box>
            <Button
              variant="plain"
              onClick={toggleOpenModal}
              disabled={(acceptTrans && true) || false}
              sx={{
                position: 'absolute', top: 0, right: 0,
                display: 'block', color: '#947EFE', fontWeight: 500, borderBottom: '1px solid #947EFE',
                p: 0,
                borderRadius: 0,
                m: 0,
              }}
            >
              {t('active_table_confirm')}
            </Button>
          </ListItemContent>
        </ListItem>
        <ListDivider />
      </List>
      <ModalConfirm openModal={openModalConfirm}
        toggleOpenModal={toggleOpenModal}
        onSubmit={onSubmit}
        amount={amount.toString()}
        is_amount_changeable={listItem.is_amount_changeable}
        handleChange={handleChange}
      />
    </>
  );
};

export default ActiveItem;
