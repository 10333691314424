import { createAsyncThunk } from '@reduxjs/toolkit';

import { t } from 'i18next';

import { getBalanceAndTransactions } from '../../services/operators';
import { updateDeposit } from '../auth/slice';

export const getBalanceAndTransactionsThunk = createAsyncThunk(
  'operatorBalanceAndTransactions/getBalanceAndTransactions',
  async (_, { dispatch }) => {
    try {
      const result = await getBalanceAndTransactions();

      dispatch(updateDeposit(result.data.balance));
      return result.data;
    } catch (error) {
      console.log(t('Error fetching balance and transactions'));
      throw error;
    }
  }
);
