import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { addCard, getBanks, getJar, deleteJar, updateJar, editJar } from '../services/operators';
import { Jars, Banks } from '../utils/types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../redux/auth/selectors';

export const useJar = ({
    userId,
    searchBank,
    setSearchBank,
    pageSize,
}: {
    userId: number | null;
    searchBank: string;
    setSearchBank: Dispatch<SetStateAction<string>> | null;
    pageSize: number | null;
}) => {
    const { t } = useTranslation();
    const [items, setItems] = useState<Jars>([]);
    const [count, setCount] = useState<number>(0);
    const [banks, setBanks] = useState([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { access_token } = useSelector(getGlobalUser);
    const [update, setUpdate] = useState(false);
    const [archItems, setArchItems] = useState<Jars>([]);
    const [countArch, setCountArch] = useState<number>(0);
    const [currentArchPage, setCurrentArchPage] = useState<number>(1);
    const handlePageClick = ({ selected }: { selected: number }) => {
        setCurrentPage(selected + 1);
    };
    const handleArchPageClick = ({ selected }: { selected: number }) => {
        setCurrentArchPage(selected + 1);
    };

    const handleRestSearch = () => {

        setSearchBank && setSearchBank('');
    };

    useEffect(() => {
        if (!userId || pageSize === null) {
            return;
        }
        const fetch = async () => {
            const allCards = await getJar(userId, searchBank, currentPage, pageSize, false);
            setItems((allCards && allCards[0]) || []);
            setCount((allCards && allCards[1]) || 0);
        };
        fetch();
    }, [searchBank, currentPage, update, pageSize]);
    
    useEffect(() => {
        if (!userId || pageSize === null) {
            return;
        }
        const fetch = async () => {
            const archCards = await getJar(userId, searchBank, currentArchPage, pageSize, true);
            setArchItems((archCards && archCards[0]) || [])
            setCountArch((archCards && archCards[1]) || 0);

        };
        fetch();
    }, [ currentArchPage, update, pageSize]);
    useEffect(() => {
        const fetch = async () => {
            const allBanks = await getBanks(access_token);
            setBanks(allBanks?.filter((bank: Banks) => bank.title !== 'Other bank'));
        };
        fetch();
    }, []);

    const expDateValidate = (year: string) => {
        if (Number(year) > 2035) return 'Expiry Date Year cannot be greater than 2035';
        return;
    };

    const handleEditCard = async (
        jars: {
            id: number;
            bank_id: number;
            monthly_limit: number;
            dailyLimit: number;
            label: string;
            is_active: boolean;
            daily_transaction_limit: number;
        }[]
    ) => {
        if (!userId) {
            return;
        }
        const updatedJar = await editJar(jars);
        if (updatedJar) setItems(updatedJar[0]);
    };

    const handleAddCart = async ({
        jar_link,
        bank,
        accessibility,
        limit_by_the_day,
        limit_by_month,
        label,
        daily_transaction_limit,
    }: {
        jar_link: string;
        bank: number;
        accessibility: boolean;
        limit_by_the_day: number;
        limit_by_month: number;
        label: string;
        daily_transaction_limit: number;
    }) => {
        try {
            const newCard = await addCard(
                jar_link,
                bank,
                accessibility,
                limit_by_the_day,
                limit_by_month,
                label,
                daily_transaction_limit,
            );
            if (!newCard) {
                return;
            }
            setItems((prev: Jars) => {
                return [newCard, ...prev];
            });
        } catch (err: unknown) {
            toast.error(t('toast_addCard'));
        }
    };

    const handleDeleteCard = async (id: number) => {
        try {
            await deleteJar(id);
            setUpdate(!update);
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpdateCard = async ({
        id,
        accessibility,
    }: {
        id: number;
        accessibility: boolean;
    }) => {
        const jar = { id: id, is_active: accessibility };
        await updateJar([jar]);
    };

    return {
        cards: items,
        archItems,
        countArch,
        expDateValidate,
        handleAddCart,
        count,
        banks,
        handleDeleteCard,
        handleUpdateCard,
        handleRestSearch,
        handlePageClick,
        handleEditCard,
        handleArchPageClick
    };
};
