import React from 'react';
import { Typography, Box, Button, Sheet, Modal, IconButton } from '@mui/joy';
import useWidth from '../../hooks/useWidth';
import { useTranslation } from 'react-i18next';
import IconComponent from '../IconComponent/IconComponent';
import css from './Wallet.module.css';

const ModalWithdrawal = ({
  openWithdrawal,
  toggleOpenWithdrawal,
  amountWithdrawal,
  handleChangeWithdrawal,
  handleWithdrawal,
  disabledWithdrawal,
  validation,
  isValid,
  isInvalid,
  minAmountWithdrawal
}: {
  openWithdrawal: boolean;
  toggleOpenWithdrawal: () => void;
  amountWithdrawal: string;
  handleChangeWithdrawal: (value: string) => void;
  handleWithdrawal: () => void;
  disabledWithdrawal: boolean;
  validation: boolean;
  isValid: boolean;
  isInvalid: boolean;
  minAmountWithdrawal: string;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWidth();
  return (
    <Modal
      open={openWithdrawal}
      onClose={toggleOpenWithdrawal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: (isMobile && '80%') || '600px',
          borderRadius: '16px',
          padding: '32px 40px',
          boxShadow: '4px 2px 64px 0 rgba(69, 69, 69, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          border: 'transparent',
        }}
      >
        <IconButton onClick={toggleOpenWithdrawal} sx={{ marginLeft: 'auto' }}>
          <IconComponent paths="m10 11.9 7.5 7.5a1.3 1.3 0 0 0 2-1.9L11.8 10l7.5-7.5a1.3 1.3 0 0 0-1.9-2L10 8.2 2.5.6a1.3 1.3 0 0 0-2 1.9L8.2 10 .6 17.5a1.3 1.3 0 1 0 1.9 2l7.5-7.6Z" />
        </IconButton>
        <Typography level="h4" sx={{ textAlign: 'center', mb: 2, color: '#F1F1F1' }}>
          {t('sidebar_withdrawal_header')}
        </Typography>
        <Typography level="body-sm" sx={{ textAlign: 'center', mb: 2, color: '##ADB5BD' }}>
          {t('withdrawalText',{minAmount: minAmountWithdrawal})}
        </Typography>

        <input
          value={amountWithdrawal}
          onChange={(e: { target: { value: string } }) => handleChangeWithdrawal(e.target.value)}
          id="amountWithdrawal"
          name="amountWithdrawal"
          type="number"
          min={1}
          placeholder={t('sidebarModalPlaceholder')}
          className={`${css.input} ${isValid ? css.valid : ''} ${isInvalid ? css.invalid : ''}`}
          autoComplete="amount"
          autoFocus
        />
        {validation && (
          <Typography color="danger" level="body-sm" sx={{ mb: 2 }}>
            {t('validation_withdraw', { minAmount: minAmountWithdrawal })}
          </Typography>
        )}
        <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
          <Button
            onClick={handleWithdrawal}
            sx={{
              width: '300px',
              padding: '8px',
              background: disabledWithdrawal
                ? '#495057'
                : 'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
              color: '#F1F1F1',
              fontWeight: 600,
              fontSize: '16px',
              '&:hover': {
                background:
                  'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
              },
            }}
            disabled={disabledWithdrawal}
          >
            {t('active_table_confirm')}
          </Button>
        </Box>
      </Sheet>
    </Modal>
  );
};

export default ModalWithdrawal;
