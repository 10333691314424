import { Table, Typography } from '@mui/joy';
import React, { useState } from 'react';
import PendingTransItem from './PendingTransItem';
import { Transactions } from '../../utils/types';
import { useTranslation } from 'react-i18next';

const PendingTransaction = ({ notifications }: { notifications: Transactions[] }) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState<readonly number[]>([]);
    
    return (
        <>
            <Table
                aria-labelledby="tableTitle"
                stickyHeader
                hoverRow
                sx={{
                    '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                    '--Table-headerUnderlineThickness': '1px',
                    '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                    '--TableCell-paddingY': '4px',
                    '--TableCell-paddingX': '8px',
                }}
            >
                <thead>
                    <tr>
                        <th style={{ color: '#F1F1F1', width: '12%' }}>{t('historyTableCardname')}</th>
                        <th style={{ color: '#F1F1F1', width: '20%' }}>{t('active_table_card')}</th>

                        <th style={{ color:'#F1F1F1' }}>{t('active_table_date')}</th>
                        <th style={{ color:'#F1F1F1' }}>{t('active_table_price')}</th>
                        <th style={{ color:'#F1F1F1' }}>{t('active_table_course')}</th>
                        <th style={{ color: '#F1F1F1'}}>{t('active_table_action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {notifications &&
                        notifications.map((row: Transactions) => (
                            <PendingTransItem
                                key={row.id}
                                notification={row}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        ))}
                </tbody>
            </Table>
            {!notifications && <Typography sx={{ mt: 2, pl: 2 }}>{t('not_found')}</Typography>}
        </>
    );
};
export default PendingTransaction;