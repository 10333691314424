import React from 'react';

import css from './Orders.module.css';

import Footer from '../../components/Footer';
import CustomVerticalStepper from './Stepper';
import { Box } from '@mui/joy';
import ToggleLanguage from '../../components/ToggleLanguage/ToggleLanguage';
import { useLocation } from 'react-router-dom';

const Orders = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const key = params.get('key');

  return (
    <Box>
      <Box sx={{ position: 'absolute', top: '20px', right: '20px' }}>
        <ToggleLanguage />
      </Box>
      <div className={css.container}>
        <CustomVerticalStepper api_key={key} />
      </div>
      <Footer />
    </Box>
  );
};
export default Orders;
