import React from 'react';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/joy';
import ReactApexChart from 'react-apexcharts';
import useWidth from '../../hooks/useWidth';
const DonutChart = () => {
  const { user } = useSelector(getGlobalUser);
  const { t } = useTranslation();
  const { isMobile } = useWidth();
  const series: number[] = [
    Number(user?.frozen_limit) || 0,
    Number(user?.deposit) || 0,
    Number(user?.banned_frozen_limit) || 0,
  ];

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
      redrawOnWindowResize: true,
    },
    labels: [t('tradingBalance'), t('myWalletBalance'), t('heldInTransactions')],
    colors: ['#947efe', '#495057', '#65beff'],
    legend: {
      show: false,
      position: 'right',
      horizontalAlign: 'center',
      fontSize: '20px',
      fontWeight: 600,
      labels: {
        colors: ['#fff', '#fff', '#fff'],
      },
    },
    stroke: { width: 0 },
    tooltip: { enabled: true },
    plotOptions: {
      pie: {

        dataLabels: {},
        donut: {
          size: '85%',
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: ['#fff'],
      },
    },

    title: {
      style: {
        fontSize: '32px',
        fontWeight: 600,
        color: '#F1F1F1',
      },
    },
    subtitle: {
      style: {
        fontSize: '32px',
        fontWeight: 600,
        color: '#F1F1F1',
      },
    },
  };
  return (
    <Box sx={{ maxWidth: 317, maxHeight: 317, width: isMobile ? '100%' : '317px' }}>
      <ReactApexChart options={options} series={series} type="donut" />
    </Box>
  );
};

export default DonutChart;
