import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import api, { token } from '../../services/axiosConfig';
import { AxiosError } from 'axios';
import { User } from './types';


export const signIn = createAsyncThunk(
  'auth/signin',
  async ({ email, password }: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await api.post('auth/operator/login/', { email, password });

      // token.set(response.data.access_token);
      return response.data;
    } catch (err: unknown) {
      const error = err as AxiosError;
      toast.error(i18n.t('toast_email'), {
        position: 'top-right',
      });

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const authenticate = createAsyncThunk(
  'auth/2fa',
  async ({ email, code }: { email: string; code: string }, thunkAPI) => {
    try {
      const response = await api.post('auth/operator/2fa/authenticate/', {
        code,
        email: email,
      });
      token.set(response.data.user.access_token);
      return response.data;
    } catch (err: unknown) {
      const error = err as AxiosError;
      toast.error(i18n.t('toast_2fa'), {
        position: 'top-right',
      });

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getOperatorById = createAsyncThunk(
  'auth/update',
  async (id: number | undefined | null, thunkAPI) => {
    if (!id) {
      return;
    }
    try {
      const response = await api.get<{ user: User }>(`account/operator/${id}`);
      return response.data;
    } catch (err: unknown) {
      const error: AxiosError<unknown> = err as AxiosError;
      console.log(error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateFrozenBalance = createAsyncThunk(
  'auth/updaeFrozenBalance',
  async ({ amount, id }: { amount: number; id: number }, thunkAPI) => {
    try {
      const response = await api.patch(`account/operator/frozenTopUp/${id}`, { limit: amount });
      return response.data;
    } catch (err: unknown) {
      const error: AxiosError<unknown> = err as AxiosError;

      if (error.response?.status === 409) {
        toast.error(i18n.t('toast_frozenUpdate'), {
          position: 'top-right',
        });
      }

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const resetFrozenBalance = createAsyncThunk(
  'auth/resetFrozenBalance',
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await api.patch(`account/operator/frozenRemove/${id}`);
      return response.data;
    } catch (err: unknown) {
      const error: AxiosError<unknown> = err as AxiosError;

      if (error.response?.status === 409) {
        toast.error(i18n.t('toast_frozenReset'), {
          position: 'top-right',
        });
      }

      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateUserTransactionStatus = createAsyncThunk(
  'auth/updateUserTransactionStatus',
  async (
    { id, isActiveTransaction }: { id: number; isActiveTransaction: boolean },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.patch(`account/operator/${id}`, { user: { isActiveTransaction } });
      toast.success(i18n.t("toastActivatedTrading"))
      return response.data;
    } catch (err: unknown) {
      const error = err as AxiosError;
      toast.error(i18n.t('toast_trading_balance'), {
        position: 'top-right',
      });

      return rejectWithValue(error.message);
    }
  }
);

export const updateNotificationStatus = createAsyncThunk(
  'auth/updateUserNotificationStatus',
  async (
    { id, is_notification_enabled }: { id: number; is_notification_enabled: boolean },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.patch(`account/operator/${id}`, { user: { is_notification_enabled } });
      if (is_notification_enabled) {
        toast.success(i18n.t("toast_notification_enabled"));
      } else {
        toast.success(i18n.t("toast_notification_disabled"));
      }
      return response.data;
    } catch (err: unknown) {
      const error = err as AxiosError;
      toast.error(i18n.t('toast_notification_error'), {
        position: 'top-right',
      });

      return rejectWithValue(error.message);
    }
  }
);

export const updateUserPayoutStatus = createAsyncThunk(
  'auth/updateUserPayoutStatus',
  async (
    { id, is_active_payout }: { id: number; is_active_payout: boolean },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.patch(`account/operator/${id}`, { user: { is_active_payout, payout_trading_enabled: new Date() } });
      if (!is_active_payout) {
        toast.success(i18n.t("toastDisabledTradingPayout"))
      } else {
        toast.success(i18n.t("toastActivatedTradingPayout"))
      }

      return response.data;
    } catch (err: unknown) {
      const error = err as AxiosError;
      toast.error(i18n.t('toastErrorTradingPayout'), {
        position: 'top-right',
      });

      return rejectWithValue(error.message);
    }
  }
);

export const logOut = () => {
  token.unset();
  localStorage.removeItem('persist:auth');
  window.location.reload();
};

export const registration = async (formData: object, verificationlink: string | undefined) => {
  try {
    const { data } = await api.put(`auth/registration/${verificationlink}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return data;
  } catch {
    toast.error(i18n.t('toast_registration_error'), {
      position: 'top-right',
    });
  }
};

export const forgotPassword = async (email: string) => {
  try {
    const { data } = await api.post(`auth/operator/forgot-password`, {
      email,
    });

    return data;
  } catch {
    toast.error(i18n.t('toast_forgotPassword'), {
      position: 'top-right',
    });
  }
};

export const resetPassword = async (email: string, resetToken: string, newpassword: string) => {
  try {
    const { data } = await api.post(`auth/operator/reset-password`, {
      email,
      resetToken,
      newpassword,
    });

    return data;
  } catch {
    toast.error(i18n.t('toast_resetPassword'), {
      position: 'top-right',
    });
  }
};

export const getActiveTransactions = createAsyncThunk(
  'trans/getActiveTransactions',
  (data: any) => {
    try {
      return data;
    } catch {
      toast.error(i18n.t('toast_error'), {
        position: 'top-right',
      });
    }
  }
);

export const refreshNotificationStatus = async (operatorId: any) => {
  try {
    const { data } = await api.get(`account/operator/notification/status/${operatorId}`);
    return data;
  } catch (error: any) {
    console.log(error.message);
  }
};

export const getActivePayouts = createAsyncThunk(
  'payouts/getActivePayouts',
  (data: any) => {
    try {
      return data;
    } catch {
      toast.error(i18n.t('toast_error'), {
        position: 'top-right',
      });
    }
  }
);

//todo export const refreshToken = createAsyncThunk(
//   'auth/refresh',
//   async (refreshtoken: string | undefined, thunkAPI: any) => {
//     try {
//       const { data } = await api.post(`auth/refresh`, { refreshToken: refreshtoken });

//       localStorage.setItem('persist:auth', data.user.accesstoken);
//       return data;
//     } catch (error: any) {
//       toast.error('Something went wrong with refresh', {
//         position: 'top-right',
//       });
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

export const updateUserApi = createAsyncThunk(
  'auth/updateUserApi',
  async (
    { id, mobile_token }: { id: number; mobile_token: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.patch(`account/operator/${id}`, { user: { mobile_token } });
      return response.data;
    } catch (err: unknown) {
      const error = err as AxiosError;
      toast.error(i18n.t('toast_generateApi_error'), {
        position: 'top-right',
      });

      return rejectWithValue(error.message);
    }
  }
);

 export const refreshToken = createAsyncThunk(
  'auth/operator/refresh',
   async (refresh_token: string | undefined, thunkAPI) => {
     try {
       const { data } = await api.post(`auth/operator/refresh`, { refresh_token });
       
       token.set(data?.access_token);
       return data;
     } catch (error) {
      toast.error('Something went wrong with refresh', {
         position: 'top-right',
       });
       localStorage.clear();
       return thunkAPI.rejectWithValue(error);
    }
  }
);