import { Box, IconButton, Typography } from '@mui/joy';
import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import useWidth from '../../hooks/useWidth';
import { toggleSidebar } from '../../utils/closeSidebar';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import ToggleLanguage from '../ToggleLanguage/ToggleLanguage';
import { useTranslation } from 'react-i18next';
import Course from '../Course';
import Logo from '../Logo/Logo';
const Header = ({ showLogo }: { showLogo: boolean }) => {
  const { isMobile } = useWidth();
  const { t } = useTranslation();
  const { isLargeDesktop } = useWidth();
  const { user, isLoggedIn } = useSelector(getGlobalUser);

  return (
    <Box
      component="header"
      sx={{
        width: '100%',
        borderBottom: '1px solid var(--joy-palette-divider)',
        padding: isMobile ? '10px 0 0 0' : '10px 20px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        {!isLargeDesktop && (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <IconButton
              onClick={() => toggleSidebar()}
              variant="outlined"
              color="neutral"
              size="sm"
            >
              <GiHamburgerMenu />
            </IconButton>
            <ToggleLanguage />
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', }}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', }}>{(showLogo && isLargeDesktop) && <Logo />}</Box>

        {isLoggedIn && !isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', }}>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: showLogo ? '60px' : 1 }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
                <path
                  fill="#CED4DA"
                  d="M8.4 5.5H7l-2 8h1l.5-1.9h2.3l.5 1.9h1l-2-8Zm-1.8 5.3 1-4.4 1 4.4h-2Zm4.6 2.7v-8h1v8h-1Zm.2 1H4v-10h10v4.8c1.2-.8 2.6-1 4-.8v-2h-2v-2a2 2 0 0 0-2-2h-2v-2h-2v2H8v-2H6v2H4a2 2 0 0 0-2 2v2H0v2h2v2H0v2h2v2a2 2 0 0 0 2 2h2v2h2v-2h2v2h2v-2c-.3-.6-.5-1.3-.6-2Zm2.1-.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0Zm3.5 5.5c-2.3 0-7 1.2-7 3.5v1.5h14V23c0-2.3-4.7-3.5-7-3.5Z"
                />
              </svg>
              <Typography
                level="title-lg"
                sx={{ ml: 1, color: '#F1F1F1', }}
              >
                {'Operator'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
              {user?.acquiring_tax !== undefined && (
                <>
                  <Typography
                    level="body-md"
                    sx={{ color: '#868E96', fontWeight: 500, marginRight: '4px' }}
                  >
                    {t('details_trans_tax')} Qac:
                  </Typography>
                  <Typography
                    level="body-md"
                    sx={{ color: '#F1F1F1', fontWeight: 500, marginRight: '40px' }}
                  >
                    {user.acquiring_tax}%
                  </Typography>
                </>

              )}
              <Typography
                level="body-md"
                sx={{ color: '#868E96', fontWeight: 500, marginRight: '4px' }}
              >
                {t('details_trans_tax')} P2P:
              </Typography>
              <Typography
                level="body-md"
                sx={{ color: '#F1F1F1', fontWeight: 500, marginRight: '40px' }}
              >
                {user.tax}%
              </Typography>

              <Typography level="body-md"
                sx={{ color: '#868E96', fontWeight: 500, marginRight: '4px' }}
              >
                {t('history_table_course')}:
              </Typography>
              <Course />
              <Box sx={{ display: 'flex', justifyContent: 'center', marginRight: '40px', alignItems: 'center' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
                  <path
                    fill="#ADB5BD"
                    d="M10 .5a5 5 0 1 1 0 10 5 5 0 0 1 0-10ZM10 13c5.5 0 10 2.2 10 5v2.5H0V18c0-2.8 4.5-5 10-5Z"
                  />
                </svg>
                <Typography level="body-md" sx={{ ml: 1, color: '#F1F1F1', fontWeight: 500 }}>
                  {user.name}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {isLargeDesktop && <ToggleLanguage />}
      </Box>
      {isLoggedIn && isMobile && (
        <Box
          sx={{
            display: 'flex',
            gap: '5px',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            mb: 1,
            mt: 2
          }}
        >
          <Box sx={{ display: 'block' }}>
            <Typography level="body-sm" sx={{ color: '#F1F1F1', fontSize: '14px' }}>
              {t('details_trans_tax')} P2P: {user.tax}%
            </Typography>
            <Typography level="body-sm" sx={{ color: '#F1F1F1', fontSize: '14px' }}>
              {t('details_trans_tax')} QAC: {user.acquiring_tax}%
            </Typography></Box>
          <Course />
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
              <path
                fill="#ADB5BD"
                d="M10 .5a5 5 0 1 1 0 10 5 5 0 0 1 0-10ZM10 13c5.5 0 10 2.2 10 5v2.5H0V18c0-2.8 4.5-5 10-5Z"
              />
            </svg>
            <Typography level="title-sm" sx={{ ml: 1, color: '#F1F1F1' }}>
              {user.name}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default Header;
