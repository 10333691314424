import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { refreshToken } from '../redux/auth/operations';
import { store } from '../redux/store';
const API = process.env.REACT_APP_BACKEND_URL;

const api = axios.create({
  baseURL: API,
});

export const token = {
  set(token: string) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  },
  unset() {
    api.defaults.headers.common.Authorization = '';
  },
};

api.interceptors.request.use(
  async config => {
    const persistData: string | null = localStorage.getItem('persist:auth');
    if (persistData !== null) {
      const parsedData = JSON.parse(persistData);
    const token = JSON.parse(parsedData.access_token);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } 
    }
   

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


let isRefreshing = false;
// Define the structure of a retry queue item
interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];

api.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && originalRequest.url !== "auth/operator/refresh") {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const persistData:string | null= localStorage.getItem('persist:auth');
          if (persistData) {
            const parsedData = JSON.parse(persistData);
            const refresh_token = JSON.parse(parsedData.refresh_token);
            if(refresh_token) {
              await store.dispatch(refreshToken(refresh_token));
              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                api
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });
              refreshAndRetryQueue.length = 0;
              return api(originalRequest);
            } else {
              localStorage.clear();
              window.location.replace('/login');
              return Promise.reject(error);
            }
            
          }
        } catch (refreshError) {
          localStorage.clear();
          window.location.replace('/login');
          return Promise.reject(refreshError);

        } finally {
          isRefreshing = false;
        }
      } else {
        return new Promise<void>((resolve, reject) => {
          refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
        });
      }
    }

    if (error.response?.status === 'CORS error') {
      toast.error('CORS error');
    }

    return Promise.reject(error);
  }

);

export default api;