import React from 'react';
import { Typography, Sheet, Modal, IconButton, Box } from '@mui/joy';
import useWidth from '../../hooks/useWidth';
import IconComponent from '../IconComponent/IconComponent';

const ModalMyWallet = ({
  isOpen,
  onClose,
  icon,
  content,
}: {
  isOpen: boolean;
  onClose: () => void;
  icon: JSX.Element;
  content: string;
}) => {
  const { isMobile } = useWidth();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: (isMobile && '80%') || '600px',
          borderRadius: '16px',
          borderColor: 'transparent',
          padding: '32px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <IconButton onClick={onClose} sx={{ marginLeft: 'auto' }}>
          <IconComponent paths="m10 11.9 7.5 7.5a1.3 1.3 0 0 0 2-1.9L11.8 10l7.5-7.5a1.3 1.3 0 0 0-1.9-2L10 8.2 2.5.6a1.3 1.3 0 0 0-2 1.9L8.2 10 .6 17.5a1.3 1.3 0 1 0 1.9 2l7.5-7.6Z" />
        </IconButton>
        <Box
          sx={{
            width: '130px',
            height: '130px',
            borderRadius: '50%',
            background: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.04))',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid rgba(255, 255, 255, 0.16)',
          }}
        >
          {icon}
        </Box>
        <Typography
          level="title-md"
          sx={{ textAlign: 'center', color: '#F1F1F1', marginTop: '30px'}}
        >
          {content}
        </Typography>
      </Sheet>
    </Modal>
  );
};

export default ModalMyWallet;
