import { Box, Button, Typography } from '@mui/joy';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import { AppDispatch } from '../../redux/store';
import { getBalanceAndTransactionsThunk } from '../../redux/operatorBalanceAndTransactions/thunks';
import { selectTransactions } from '../../redux/operatorBalanceAndTransactions/selectors';
import { DepositTransactionsTable } from './DepositTransactionsTable';
import './styles.css';

const TopUpDeposit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useSelector(getGlobalUser);
  const transactions = useSelector(selectTransactions);

  const handleUpdateDeposit = () => {
    dispatch(getBalanceAndTransactionsThunk());
  };

  useEffect(() => {
    dispatch(getBalanceAndTransactionsThunk());
  }, []);

  const updateButtonDisabled = !user.deposit_wallet;

  return (
    <Box sx={{ mb: 3, p: 3 }}>
      <Typography level="h2" sx={{ p: '20px 0', mb: 2, textAlign: 'center' }}>
        {t('topup_header')}
      </Typography>
      <Typography level="title-lg" sx={{ textAlign: 'center', mb: 3 }}>
        {user.deposit_wallet
          ? `${t('user_deposit_wallet')}: ${user.deposit_wallet}`
          : t('user_no_deposit_wallet')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '12px',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Button type="button" onClick={handleUpdateDeposit} disabled={updateButtonDisabled}>
          {t('topup_btnUpdate')}
        </Button>
      </Box>

      <Typography level="title-lg" sx={{ textAlign: 'center', mb: 3, mt: 6 }}>
        {t('Transactions')}
      </Typography>

      {!transactions?.length && (
        <Typography level="title-md" sx={{ textAlign: 'center' }}>
          {t('Your deposit transactions will appear here once you begin to top up')}
        </Typography>
      )}

      <DepositTransactionsTable transactions={transactions} />
    </Box>
  );
};

export default TopUpDeposit;
