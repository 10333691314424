import React from "react";
import { Box } from '@mui/joy';
import ReactApexChart from 'react-apexcharts';

const DonutBalance = ({ series, options }: { series: number[], options: ApexCharts.ApexOptions }) => {
    return (
        <Box sx={{ width: 100, height: 62 }}>
            <ReactApexChart options={options} series={series} type="donut" />
        </Box>
    );
};

export default DonutBalance;