import { Box } from '@mui/joy';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import useWidth from '../../../hooks/useWidth';
import { Stats } from '../../../utils/types';

const CircleChart = ({ data }: { data: Stats | null }) => {
  const { t } = useTranslation();
  const { isMobile } = useWidth();
  const totalSuc =
    (data?.statistic?.countOfSuccessfulTransactions ?? 0) +
    (data?.acqStatistic.countOfSuccessfulTransactionsAcq ?? 0);
  const totalFailed =
    (data?.statistic?.countOfFailedTransactions ?? 0) +
    (data?.acqStatistic.countOfFailedTransactionsAcq ?? 0);
  const series: number[] = [
    totalSuc || 0,
    totalFailed || 0,
  ];

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
    },
    labels: [t('dashboard_transactions_Sec'), t('dashboard_transactions_Fl')],
    colors: ['#18E806', '#EB5757'],
    legend: {
      show: false,
    },

    plotOptions: {
      pie: {
        dataLabels: {},
        donut: {
          size: '85%',
          labels: {
            show: false,
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: ['#fff'],
      },
    },
    stroke: { width: 0 },
    tooltip: {
      enabled: true,
    },
  };

  return (
    <Box sx={{ maxWidth: 280, width: isMobile ? '100%' : '280px' }}>
      <ReactApexChart options={options} series={series} type="donut" />
    </Box>
  );
};

export default CircleChart;
