import React from 'react';
import { Box, Button, Sheet, Modal, IconButton, FormControl, Input, FormHelperText } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import useWidth from '../../hooks/useWidth';
import IconComponent from '../../components/IconComponent/IconComponent';
import css from './ActivePayouts.module.css';

const ModalCancel = ({
  openModalCancel,
  toggleOpenCancelModal,
  handleChangeCancelReason,
}: {
  openModalCancel: boolean;
  toggleOpenCancelModal: () => void;
  onSubmitCancel: () => void;
  handleChangeCancelReason: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useWidth();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      toggleOpenCancelModal();
    } else {
      form.reportValidity();
    }
  };

  return (
    <Modal
      open={openModalCancel}
      onClose={toggleOpenCancelModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'transparent',
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: (isMobile && '80%') || '600px',
          borderRadius: '16px',
          padding: '32px 40px',
          boxShadow: '4px 2px 64px 0 rgba(69, 69, 69, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          border: 'transparent',
        }}
      >
        <IconButton onClick={toggleOpenCancelModal} sx={{ marginLeft: 'auto' }}>
          <IconComponent
            paths="m10 11.9 7.5 7.5a1.3 1.3 0 0 0 2-1.9L11.8 10l7.5-7.5a1.3 1.3 0 0 0-1.9-2L10 8.2 2.5.6a1.3 1.3 0 0 0-2 1.9L8.2 10 .6 17.5a1.3 1.3 0 1 0 1.9 2l7.5-7.6Z" />
        </IconButton>

        <form onSubmit={handleFormSubmit}>
          <label>{t('payout_cancel_reason')}</label>
          <FormControl sx={{ flex: '0 1 auto', minWidth: isMobile ? '100%' : '200px' }}>
            <Input
              onChange={(e) => handleChangeCancelReason(e.target.value)}
              id="cancel_reason"
              name="cancel_reason"
              autoFocus
              className={css.input}
              required={true}
            />
            <FormHelperText>{t('payout_cancel_description')}</FormHelperText>
          </FormControl>
          <br></br>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Button
              type="submit"
              sx={{
                width: isMobile ? '100%' : '300px',
                background: 'linear-gradient(135deg, rgba(221, 79, 79, 1) 0%, rgba(172, 47, 47, 1) 100%)',
                color: '#FFF',
                '&:hover': {
                  background: 'linear-gradient(135deg, rgba(221, 79, 79, 0.8) 0%, rgba(172, 47, 47, 0.8) 100%)',
                  color: '#f1f1f1',
                },
              }}
            >
              {t('sidebar_modal_btn')}
            </Button>
          </Box>
        </form>
      </Sheet>
    </Modal>
  )
}

export default ModalCancel;
