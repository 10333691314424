import { Box, Button, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import ChartActivity from '../../components/Dashboard/Charts/ChartActivity';
import Profit from '../../components/Dashboard/Widgets/Profit';
import Transactions from '../../components/Dashboard/Widgets/Transactions';
import Turnovers from '../../components/Dashboard/Widgets/Turnovers';

import useWidth from '../../hooks/useWidth';
import { formatDateBE, formatDateText } from '../../utils/datePickerFormat';
import DatePickerModal from '../../components/DatePicker';
import { getAllStats } from '../../services/operators';
import { useSelector } from 'react-redux';
import { getGlobalUser } from '../../redux/auth/selectors';
import { Stats, itemType } from '../../utils/types';
type ItemRange = {
  selection: itemType;
}
const Dashboard = () => {
  const { user } = useSelector(getGlobalUser);

  const [datePickerState, setDatePickerState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [filterQueries, setFilterQueries] = useState({
    sDate: formatDateBE(datePickerState[0].startDate),
    fDate: formatDateBE(datePickerState[0].endDate),
  });
  const [open, setOpen] = useState(false);
  const [stats, setStats] = useState<null | Stats>(null);
  const { isMobile, isLargeDesktop } = useWidth();
  const { t } = useTranslation();
  const toggleOpenModal = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!user?.id) {
      return;
    }
    const fetch = async () => {
      const statsData = await getAllStats(filterQueries);

      setStats(statsData);
    };
    fetch();
  }, [filterQueries]);

  const handleChange = (item: ItemRange) => {
    setDatePickerState([item.selection]);
  };

  const handleAddDeadline = async () => {
    setFilterQueries(prev => {
      return {
        ...prev,
        sDate: formatDateBE(datePickerState[0].startDate),
        fDate: formatDateBE(datePickerState[0].endDate),
      };
    });

    setOpen(!open);
  };
  return (
    <Box sx={{ mb: 3, pl: (isMobile && '0') || 3, width: '100%' }}>
      <Typography component="h2" sx={{ p: '20px 0 16px 0 ', fontWeight: 600, fontSize: isMobile ? '20px':'28px', color:"#F1F1F1"}}>
        {t('dashboard_header')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', mb: '36px' }}>
        <Typography level="title-md" sx={{ fontWeight: 400, fontSize:isMobile?'14px':'18px', color:'#CED4DA' }}> {t('periods_search')}</Typography>
        <Box>
         
          <Button variant="outlined" color="neutral" onClick={toggleOpenModal} sx={{ p: isMobile?'12px 8px':'12px 19px', }}> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" fill="none"><path fill="url(#a)" d="M18 9.5v7a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-7h18Zm-5-9a1 1 0 0 1 1 1v1h2a2 2 0 0 1 2 2v3H0v-3a2 2 0 0 1 2-2h2v-1a1 1 0 0 1 2 0v1h6v-1a1 1 0 0 1 1-1Z" /><defs><linearGradient id="a" x1=".2" x2="18" y1="10.8" y2="10.8" gradientUnits="userSpaceOnUse"><stop stopColor="#684FDD" /><stop offset="1" stopColor="#2F92AC" /></linearGradient></defs></svg>
            <Typography sx={{ ml: 1, color: '#F1F1F1', fontSize: isMobile ? '10px' : '14px', }}>
             {(filterQueries.sDate &&
              filterQueries.fDate &&
              `${formatDateText(filterQueries.sDate)} - ${formatDateText(filterQueries.fDate)}`) ||
              t('search_period')} 
</Typography>
            
          </Button>
        </Box>
      </Box>
     <Box sx={{display: !isLargeDesktop ? 'block' : 'flex'}}>
      <Box sx={{mr: isMobile ? 0 : 3}}>
        <Turnovers stats={stats} filterQueries={filterQueries} />
        <Profit stats={stats} filterQueries={filterQueries} />
      </Box>
      <Transactions stats={stats} filterQueries={filterQueries} />
      </Box>

      {/* <ChartActivity
        title={t('dashboard_chartHeader')}
        chartLabels={[
          '01/01/2023',
          '02/01/2023',
          '03/01/2023',
          '04/01/2023',
          '05/01/2023',
          '06/01/2023',
          '07/01/2023',
          '08/01/2023',
          '09/01/2023',
          '10/01/2023',
          '11/01/2023',
        ]}
        chartData={[
          {
            name: t('dashboard_optionTwo'),
            type: 'column',
            fill: 'solid',
            data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
          },
          {
            name: t('dashboard_optionOne'),
            type: 'area',
            fill: 'gradient',
            data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
          },
        ]}
      /> */}

      <DatePickerModal
        handleChange={handleChange}
        open={open}
        setOpen={setOpen}
        datePickerState={datePickerState}
        handleAddDeadline={handleAddDeadline}
      />
    </Box>
  );
};

export default Dashboard;
