import { Box, Modal, Typography, Sheet, Button } from '@mui/joy';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWidth from '../../hooks/useWidth';
import { formatingCardNumber } from '../../utils/formationCardNum';
import { Cards } from '../../utils/types';


const ArchivedCardItem = ({
    card,
    handleDelete,
}: {
    card: Cards;
    handleDelete: (id: number) => void;
}) => {
    const { t } = useTranslation();
    const { isMobile } = useWidth();
    const [restoreOpen, setRestoreOpen] = useState(false);
    const handleDeleteCard = async () => {
        handleDelete(card.id);
        setRestoreOpen(false)
    };

    return (
        <>
            <tr
                style={{
                    backgroundColor: 'transparent',
                }}
            >
                <td>
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                        <Box>
                            <Typography level="body-sm" sx={{ color: '#F1F1F1', overflowWrap: 'anywhere', }}>
                                {formatingCardNumber(card.pocket_address)}
                            </Typography>
                            <Typography level="body-sm" sx={{ fontWeight: 700 }}>
                                {card.label}
                            </Typography>
                        </Box>
                    </Box>
                </td>
                <td>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography level="body-sm" sx={{ color: '#CED4DA', }}>
                            {t('card_table_turnoverDay', {
                                daily: (card.daily_balance && card.daily_balance) || 0,
                            })}
                        </Typography>
                        <Typography level="body-sm" sx={{ color: '#CED4DA',}}>
                            {t('card_table_turnoverMonth', {
                                monthly: (card.month_balance && card.month_balance) || 0,
                            })}
                        </Typography>
                    </Box>
                </td>
                <td>
                    <Typography level="body-sm" sx={{ color: '#CED4DA' }}>
                        {`${card.daily_limit} UAH`}
                    </Typography>
                </td>
                <td>
                    <Typography level="body-sm" sx={{ color: '#CED4DA', }}>
                        {`${card.month_limit} UAH`}
                    </Typography>
                </td>
                <td style={{ color: '#CED4DA' }}>{card.bank.bank.title}</td>
                <td>
                    <Typography
                        level="body-sm"
                        sx={{
                            bgcolor: (card.tag && 'rgba(104, 79, 221, 0.2)') || 'transparent',
                            width: 'fit-content',
                            p: '2px 10px',
                            borderRadius: '8px',
                            color: '#F1F1F1',

                        }}
                    >
                        {(card.tag && `#${card.tag.title}`) || t('not_found')}
                    </Typography>
                </td>
                <td>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                        <Button variant="outlined"
                            className={"btnGradient"}
                            sx={{
                                p: '10px 0',
                                width: '80px',
                                fontSize: '14px',
                                color: '#f1f1f1',
                                '&:hover': {
                                    background:
                                        'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',

                                },

                            }} onClick={() => setRestoreOpen(!restoreOpen)}>{t("cardRestoreBtn")}</Button>
                    </Box>
                </td>
            </tr>
            {restoreOpen && (
                <Modal
                    open={restoreOpen}
                    onClose={() => {
                        setRestoreOpen(!restoreOpen);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Sheet
                        variant="outlined"
                        sx={{
                            width: (isMobile && '80%') || '40%',
                            borderRadius: 'md',
                            p: 3,
                            boxShadow: 'lg',
                        }}
                    >
                        <Typography level="body-lg" sx={{ textAlign: 'center', mb: 2 }}>
                            {t('cardModalRestoreHeader')}
                        </Typography>
                        <Button
                            sx={{
                                display: 'block', m: '0 auto',
                                background:
                                    'linear-gradient(135deg, rgba(104, 79, 221, 1) 0%, rgba(47, 146, 172, 1) 100%)',
                                color: '#f1f1f1',
                                '&:hover': {
                                    background:
                                        'linear-gradient(135deg, rgba(104, 79, 221, 0.8) 0%, rgba(47, 146, 172, 0.8) 100%)',
                                },
                            }}
                            onClick={handleDeleteCard}
                        >
                            {t('cardModalRestoreBtn')}
                        </Button>
                    </Sheet>
                </Modal>
            )}
        </>
    );
};
export default ArchivedCardItem;